// Import the functions you need from the SDKs you need
import * as firebase from 'firebase'

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA8LH3Z0opXu2R07LiBww0y9c1-V08Pj2Y",
    authDomain: "aruna-ias-academy.firebaseapp.com",
    projectId: "aruna-ias-academy",
    storageBucket: "aruna-ias-academy.appspot.com",
    messagingSenderId: "954892962249",
    appId: "1:954892962249:web:1a486889cd575a18834018",
    measurementId: "G-03B64P4727"
};

firebase.initializeApp(firebaseConfig);


// Initialize Firebase
export let  defaultAuth = firebase.auth();


/*
 * Simple editor component that takes placeholder text as a prop
 */

import ReactQuill from 'react-quill';
import React, {useEffect, useMemo, useState} from 'react';


const Editor = (props) =>  {

    let [editorHtml,setEditorHtml] = useState(props.value);

    useEffect(()=> {
        if(props.value){
            setEditorHtml(props.value)
        }
    },[]);

    function handleChange (html) {
        console.log('html',html);
        setEditorHtml(html)
    }

    return (
        <div>
            <ReactQuill
                theme={'snow'}
                onChange={handleChange}
                value={editorHtml}
                onBlur={()=> props.onChange(editorHtml)}
                modules={Editor.modules}
                formats={Editor.formats}
                // bounds={'.app'}
                // placeholder={this.props.placeholder}
            />
        </div>
    )
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];


export default Editor

import React, {useState} from 'react';
import './login.css'
import * as firebase from 'firebase'
import {defaultAuth} from "../../../config/firebaseConfig";
import * as axios from "axios";
import {base_url} from "../../../utils";


const Login = () => {

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const googleAuth = new firebase.auth.GoogleAuthProvider();

    const onSignIn  = () => {
        defaultAuth.signInWithPopup(googleAuth).then((user)=> {
            let token = '';
            defaultAuth.currentUser.getIdToken().then(data =>{
                localStorage.setItem('access_token',data);
                axios.defaults.headers.common['x-auth-token'] = `${data}`;
                token = data;
                console.log(data)
                alert('Logged in')
                axios.post(`${base_url}/user/create`,{}).then(res=>console.log(res))
            });


        });
    };


    return (
        <div className="userLogin">
            <h1 className="title">ACHARYA IAS BENGALURU</h1>
            <div className="name">
                <button onClick={(event)=>{
                    onSignIn();
                    console.log(name, password)
                }} >Sign In With Google</button>
                <button onClick={(event)=>{
                    defaultAuth.signOut().then(()=>{
                        alert('user successfully logged out')
                    });
                }} >Sign Out</button>
            </div>
        </div>
    );
};

export default Login;

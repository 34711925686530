import React, {useEffect, useState} from 'react';
import axios from "axios";
import {base_url} from "../../../utils";
import {Multiselect} from "multiselect-react-dropdown";
import './UserPermissions.css'
import Select from "react-select";
import Dropdown from "../../user/components/DropDown/Dropdown";
import {useDispatch} from "react-redux";
import Popup from "../../../components/Popup/Popup";
import request from "../../../utils/apiRequest";


const UserPermissions = () => {

    const [showConfirmPopup,setShowConfirmPopup] = useState(false);
    const [allUsers , setAllUsers] = useState([]);
    const [videoCourses,setVideoCourses] = useState([])
    const [notes,setNotes] = useState([])
    const [testSeriesList,setTestSeriesList] = useState([])
    const [list,setList] = useState([])
    const [selectedUserDetails,setSelectedUserDetails] = useState([])
    const [selectedCourseType,setSelectedCourseType] = useState({id:'video_courses',label:'Video Courses'});
    const [allOptions,setAllOptions] = useState([{id:'video_courses',label:'Video Courses'},{id:'notes',label:'Notes'},{id:'test_series',label:'test_series'}])

    const dispatch = useDispatch();

    useEffect(()=> {
        getAllUsers();
        getAllTestSeries();
        getAllNotes();
        getAllVideoCourses()
    },[]);

    const getAllVideoCourses = () => {
        dispatch({type: "INCREMENT", step: 1})
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        axios.get(`${base_url}/course_list/all`).then(res => {
            dispatch({type: "DECREMENT",step: 1})
            setVideoCourses(res.data);
            console.log("res.data",res.data);
            getOptions()
        })
    };

    const getAllTestSeries = () => {
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        axios.get(`${base_url}/test_series_list/all`).then(res => {
            setTestSeriesList(res.data);
            console.log("res.data",res.data)
        })
    };

    const getAllNotes = () => {
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        axios.get(`${base_url}/notes_list/all`).then(res => {
            setNotes(res.data);
            console.log("res.data",res.data)
        })
    };

    const getAllUsers = () => {
        dispatch({type: "INCREMENT", step: 1});
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        axios.get(`${base_url}/users/all?limit=1000`).then(res => {
            dispatch({type: "DECREMENT",step: 1})
            setAllUsers(res.data.map(item => ({...item,is_selected:true,label:item.email} )));
            console.log("all users",res.data)
        })
    };

    const getUserSubscriptions = (userId,index) => {
        dispatch({type: "INCREMENT", step: 1});
        if(userId) {
            request(`/subscription/get_user_subscription?user_id=${userId}`, dispatch).then(res => {
                dispatch({type: "DECREMENT", step: 1});
                if (res) {
                    setVideoCourses(videoCourses.map(item => ({
                        ...item,
                        is_selected: res.course_subscribe.findIndex(ele => item.id === ele) !== -1
                    })))
                    setNotes(notes.map(item => ({
                        ...item,
                        is_selected: res.notes_subscribe.findIndex(ele => item.id === ele) !== -1
                    })))
                    setTestSeriesList(testSeriesList.map(item => ({
                        ...item,
                        is_selected: res.test_subscribe.findIndex(ele => item.id === ele) !== -1
                    })))
                }
                console.log("all users", res.data)
            })
        }
    };



    function getOptions(data){
        console.log('selectedCourseType',videoCourses)
        if(data?.id === 'video_courses'){
            setList(videoCourses.map(item => { return {...item,label:item.name,id:item.id,key:'course'}}))
        }
        else if(data?.id === 'notes'){
            setList(notes.map(item => { return {...item,label:item.name,id:item.id,key:'notes'}}))
        }
        else if(data?.id === 'test_series'){
            setList(testSeriesList.map(item => { return {...item,label:item.name,id:item.id,key:'test'}}))

        }

        console.log('list',list)
    }

    const onUserSelect = (option,type) => {
        let index = allUsers.findIndex(item => item.label === option.label)
        setSelectedUserDetails(allUsers[index]);
        console.log('selected User',allUsers[index].id);
        allUsers.map((item => ({...item,is_selected: item.id === allUsers[index].id})))
        getUserSubscriptions(allUsers[index].id,index)
    };

    const onOptionSelect = (option,type) => {
        let index = allOptions.findIndex(item => item.label === option.label)
        setSelectedCourseType({...allOptions[index]});
        setList([]);
        getOptions({...allOptions[index]})
    };

    const onHandleChange = (option,type) => {
        let index = list.findIndex(item => item.label === option.label)
        let listCopy = list.map(item => ({...item}));
        listCopy[index] = {...listCopy[index],is_selected:!listCopy[index].is_selected};
        console.log('onUserSelect',listCopy);
        setList(listCopy)
    };

    function updatePermissions(e) {
        const url = `${base_url}/subscription/subscribe_by_admin`;
        axios.post(url, {
            user_id: selectedUserDetails.id,
            ids: list.filter(item => item.is_selected).map(ele => ele.id),
            type: selectedCourseType.id === 'video_courses' ? 'course' : selectedCourseType.id === 'test_series' ? 'test' : 'notes'
        }).then(res => {
            console.log("response",res)
        })
    };

    function onSave() {
        updatePermissions()
        setShowConfirmPopup(false)
    }


    return (
        <div>
            <div className='layout'>
                <div className={'dropDownLayout'}>
                    <label>Select user</label>
                    <Dropdown  type={"user"} showSearch handleChange={onUserSelect}  defaultSelected={selectedUserDetails.name}  options={allUsers}/>
                </div>
                <div className={'dropDownLayout'}>
                    <label>Select course type</label>
                    <Dropdown  type={"user"} handleChange={onOptionSelect} defaultSelected={selectedCourseType.label} options={allOptions}/>
                </div>

                <div className={'dropDownLayout'}>
                    <label>Select {selectedCourseType.label} </label>
                    <Dropdown  type={"notes"} handleChange={onHandleChange} selectAll options={list}/>
                </div>
            </div>


            <button className='savePermissions' onClick={()=> setShowConfirmPopup(true)}>Save </button>
            {showConfirmPopup &&
            <Popup header={"Are you sure want to Save changes?"} onCancel={()=> setShowConfirmPopup(false)} onSave={onSave} body={
                <div>{`You are making changes related to subscription of user ${selectedUserDetails.label}. Are you sure want to Proceed`} </div>
            }/>
            }
        </div>
    );
};

export default UserPermissions;
import React, {useEffect, useState} from 'react';
import './Notes.css'
import axios from "axios";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {FormControlLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent} from "@mui/material";
import NotesChapters from "../chapters/Chapters";
import {base_url} from "../../../../utils";
import Popup from "../../../../components/Popup/Popup";
import request from "../../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import NotesCard from "../../../user/Userview/notes/notesCard";
import NotesCardCMS from "../notes_card/NotesCard";
import FormControl from "@mui/material/FormControl";
import AlertDialog from "../../../../components/Dialog/Dialog";

const Notes = () => {

    let dispatch = useDispatch()

    const [courseDetails , setCourseDetails] = useState({});
    const [courseList , setCourseList] = useState([]);
    const [currentCourse , setCurrentCourse] = useState([]);
    const [courseTags,setCourseTags] = useState([]);
    const [isAddOrUpdateCourse , setIsAddOrUpdateCourse] = useState(false);
    const [showConfirmPopup,setShowConfirmPopup] = useState(false);


    const [isAddOrUpdateChapter , setIsAddOrUpdateChapter] = useState(false);

    useEffect(()=> {
        getAllChapters();
        getAllCourseTags();
    },[]);


    function onCourseClick(course) {
        setCurrentCourse(course);
        setIsAddOrUpdateChapter(true);
    }

    const getAllChapters = () => {
        request(`/notes_list/all`,dispatch).then(res => {
            setCourseList(res);
            console.log("response ",res)
        })
    };

    const getAllCourseTags = () => {
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        request(`/tags?key=NotesList`,dispatch).then(res => {
            let tags = res.map(item => item.name)
            setCourseTags(tags);
            setCourseDetails({...courseDetails,tags:tags[0]});
            console.log("getAllTags",tags)
        })
    };

    function courseData(e) {
        let newData = {...courseDetails};
        if(e.target.name === 'amount'){
            newData[e.target.name] = parseInt(e.target.value)
        }
        else{
            newData[e.target.name] = e.target.value
        }

        setCourseDetails(newData);
        console.log(newData)
    }

    function onDeleteCourseClick(course) {
        setCurrentCourse(course);
        setShowConfirmPopup(true)
    }

    function deleteCourse() {
        request(`/notes_list/delete?notes_list_id=${currentCourse.id}`,dispatch,{method:"delete"}).then(res => {
            getAllChapters();
            setShowConfirmPopup(false)
            console.log("Delete")
        })
    }

    function onUpdateCourseClick(course) {
        console.log('course',course);
        setCourseDetails(course);
        setIsAddOrUpdateCourse(true)
    }

    const handleChange = (e: SelectChangeEvent) => {
        setCourseDetails({...courseDetails,tags:e.target.value});
    };

    function addOrUpdateCourse(e) {
        if(!courseDetails.id){
            const url =`/notes_list/new`;
            e.preventDefault();
            request(url,dispatch,{body: {
                    "name": courseDetails.name,
                    "description": courseDetails.description,
                    "amount": courseDetails.amount,
                    "is_active": true,
                    "duration": 60,
                    'tags': courseDetails.tags,
                    'is_current_affairs': false,
                    'is_mains': true,
                },
                method:"POST"
            }).then(res=>console.log(res))
        }
        else {
            const url =`${base_url}/notes_list/update`;
            request(url,dispatch,{body: {
                    "name": courseDetails.name,
                    "description": courseDetails.description,
                    "amount": courseDetails.amount,
                    "is_active": true,
                    'is_mains': true,
                    "duration": 60,
                    'is_current_affairs': false,
                    'tags': courseDetails.tags,
                    id: courseDetails.id
                },method:"PUT"
            }).then(res=>console.log(res))
        }
        getAllChapters();
    }

    function getAddCourseView (){
        return  <div className='courseWrapper'>
            <div className={'addTestSeriesButtonLayout'}><Button variant="outlined" className={'addCourseButton'} onClick={()=> setIsAddOrUpdateCourse(!isAddOrUpdateCourse)} startIcon={<AddIcon />}>
                Add New Notes
            </Button>
            </div>
            {isAddOrUpdateCourse &&
            <div className={'addCourse'}>
                <h5>Add course Details</h5>
                <div className="courseLayout">
                    <label>Name </label>
                    <input name='name' value={courseDetails.name} onChange={courseData} type="text" />
                </div>
                <div className="courseLayout">
                    <label>Description </label>
                    <textarea name='description' value={courseDetails.description} onChange={courseData} type="text" />
                </div>
                <div className="courseLayout">
                    <label>Price</label>
                    <input name ='amount' value={courseDetails.amount}  onChange={courseData} type="text" />
                </div>
                <div className="courseLayout">
                    <label>New Tag</label>
                    <input name='tags' value={courseDetails.tags} onChange={courseData} type="text" />
                </div>
                {courseTags?.length ? <FormControl fullWidth><Select
                    style={{marginTop:20,display:'inline-block'}}
                    value={courseDetails.tags}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {courseTags.map(tag => {
                        return <MenuItem key={tag.id} style={{width:300}} value={tag}>{tag}</MenuItem>
                    })}
                </Select> </FormControl>: null}
                {/*<RadioGroup*/}
                {/*    style={{display:"flex",flexDirection:'row',marginTop:10}}*/}
                {/*    aria-labelledby="demo-radio-buttons-group-label"*/}
                {/*    defaultValue="female"*/}
                {/*    name="radio-buttons-group"*/}
                {/*>*/}
                {/*    <FormControlLabel value="Notes" control={<Radio />} label="Notes" />*/}
                {/*    <FormControlLabel value="Current Affairs" control={<Radio />} label="Current Affairs" />*/}
                {/*</RadioGroup>*/}
                <button className={'testSeriesButton'} onClick= { addOrUpdateCourse}>{courseDetails.id ? "Update" +
                    " Notes" : "Add Notes"}</button>
            </div>}
            {console.log('courseList',courseList)}
            <div className='listWrapper'>
                {courseList?.map(course => {
                    return <NotesCardCMS data={course} onCourseClick={onCourseClick} onUpdateClick={onUpdateCourseClick} onDelete={onDeleteCourseClick}/>
                })}
            </div>

            {showConfirmPopup && <AlertDialog onSave={deleteCourse} handleClose={()=> setShowConfirmPopup(false)}/>}
        </div>
    }

    return (<div  className='wrapper'>
            {!isAddOrUpdateChapter ? getAddCourseView() : <NotesChapters currentCourse={currentCourse}/>}
        </div>


    );
};

export default Notes;

import React from 'react';
import './TestSeriesCard.css';

function TestSeriesCard(props) {
    return (
        <div>
            <div className="testSeriesList" onClick={()=>props.onTestSeriesClick(props.data)}>
                <p className='tName' title={props.data.name}>{props.data.name}</p>
                <p className='tDescription' title={props.data.description}>{props.data.description}</p>
                {props.data.price && <p className='tPrice'>₹ {props.data.price}</p>}
                <p className='tTag'>{props.data.tags}</p>
                <button className='button' onClick={()=>props.onTestSeriesClick(props.data)}>View Library</button>
                <button className="button" onClick={(e)=> {e.stopPropagation();props.onUpdateClick(props.data)}}>UPDATE</button>
                <button className="button" onClick={(e)=> {e.stopPropagation();props.onDelete(props.data)}}>DELETE</button>
            </div>
        </div>
    );
}

export default TestSeriesCard;

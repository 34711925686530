import { useDispatch } from "react-redux";
import {base_url} from "../utils";
import {toast} from "react-hot-toast";
import {defaultAuth} from "../config/firebaseConfig";

const endPoint = base_url;


const request = async function(url,dispatch, data, loader = true,) {
    console.log("in request",url);
    try {
        if(loader) {
            dispatch({type: "INCREMENT", step: 1})
        }
        let headers = data?.headers || {};
        headers = {
            'x-auth-token': localStorage.getItem('access_token'),
            ...headers,
        };
        if(data?.method === 'POST' || data?.method === 'PUT') {
            headers = {
                'Content-Type': 'application/json',
                ...headers,
            }
        }
        const body = data?.body ? JSON.stringify(data?.body) : undefined;
        const fullUrl = `${endPoint}${url}`;
        console.log("url",fullUrl)
        const response = await fetch(fullUrl, {
            method: data?.method || 'GET',
            headers,
            body
        });


        const responseJson = await response.json();


        // if(response.status === 200){
        //     toast('Request Success!!😊',{duration:4000,position: 'bottom-right',})
        // }
        if(response.status === 408){
            // defaultAuth.signOut()
            toast('Session Expired please login again',{duration:4000,position: 'bottom-right',})
        }
        else if(response.status === 401)
        {
            // defaultAuth.signOut()
            toast('UnAuthorized, Please login with Admin Account',{duration:4000,position: 'bottom-right',})

        }
        else if(response.status === 404){
            toast('Not found',{duration:4000,position: 'bottom-right',})
        }else if(response.status === 500){
            toast("Internal server error",{duration:4000,position: 'bottom-right',})
        } else if(response.status !== 200){
            toast(responseJson.toString(),{duration:4000,position: 'bottom-right',})
        }


        if(loader) {
            dispatch({type: "DECREMENT",step: 1})
        }
        return responseJson;
    } catch(error) {
        toast(error.toString(),{duration:4000,position: 'bottom-right',})
        console.error("Error in request",error);
        if(loader) {
            dispatch({type: "DECREMENT",step: 1})
        }

    }
}

export default request;
import React, {useEffect, useMemo, useState} from 'react';
import './QuestionAdding.css'
import Button from "@mui/material/Button";
import AddIcon from "@mui/material/SvgIcon/SvgIcon";
import QuestionCard from "./QuestionCard/QuestionCard";
import request from "../../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import 'draft-js/dist/Draft.css';
import 'react-quill/dist/quill.snow.css';
import Editor from "../../../user/components/Editor";


const QuestionAdding = (props) => {

    const [value, setValue] = useState('');


    const dispatch = useDispatch();
    const [testQuestionDetails, setTestQuestionDetails] = useState({options:["","","",""]});
    const [allQuestions , setAllQuestions] = useState([]);
    const [isUpdateOrCreate, setIsUpdateOrCreate] = useState(false);
    const [optionsCount,setOptionsCount] = useState(4);



    useEffect(() => {
        getAllQuestions()
    },[]);

    function getAllQuestions() {
        request(`/question/all_question?series_id=${props.test_series_id}`,dispatch).then(res => {
            setAllQuestions(res);
            console.log("all questions",res)
        })
    }

    const config = useMemo(()=>
            ({
                readonly: false, // all options from https://xdsoft.net/jodit/doc/,
                placeholder: props.placeholder || 'Start typings...'
            }),
        [props.placeholder]
    );

    function handleInputChange(e) {
        setTestQuestionDetails({...testQuestionDetails,[e.target.name]:e.target.value});
    }

    function handelOptionChange(value,index) {
        let options = testQuestionDetails.options;
        options[index] = value;
        setTestQuestionDetails({...testQuestionDetails,options});
        console.log("options",testQuestionDetails)
    }

    function addQuestionToTest(e) {
        e.preventDefault();
        let testQuestionData = {
            question: testQuestionDetails.question,
            options: testQuestionDetails.options,
            answer: testQuestionDetails.answer,
            description: testQuestionDetails.description
        };

        if (testQuestionDetails.id) {
            testQuestionData = {...testQuestionData, id: testQuestionDetails.id,testseries_id: props.test_series_id};
            console.log('testQuestionData', testQuestionData);
            request(`/question/update?test_series_id=${props.test_series_id}`, dispatch, {
                body: testQuestionData,
                method: 'PUT'
            }).then(res => {
                getAllQuestions()
                setTestQuestionDetails({options:['','','','']});
                setIsUpdateOrCreate(false)
            })
        } else {
            testQuestionData = {...testQuestionData, test_series_id: props.test_series_id};
            request(`/question/new?test_series_id=${props.test_series_id}`, dispatch, {
                body: testQuestionData,
                method: 'POST'
            }).then(res => {
                getAllQuestions();
                setIsUpdateOrCreate(false)
                setTestQuestionDetails({options:['','','','']})
            });
        }
    }

    const onQuestionDelete = (question) => {
        request(`/question/delete?question_id=${question.id}`,dispatch,{method:'delete'}).then(res => {getAllQuestions();console.log("Delete")})

    };

    const onQuestionUpdate = (questionDetails) => {
        console.log('onQuestionUpdate',questionDetails);
        setTestQuestionDetails({id:questionDetails.id,question:questionDetails.question,option1:questionDetails.options[0],option2:questionDetails.options[1],
            option3:questionDetails.options[2],option4:questionDetails.options[3]});
        setIsUpdateOrCreate(true);
        console.log('onQuestionUpdate',{question:questionDetails.question,option1:questionDetails.option1,option2:questionDetails.option2,
            option3:questionDetails.option3,option4:questionDetails.option4,option5:questionDetails.option5})
    };

    return (
        <div className='testSeriesDetailsWrapper'>
            <div className='addTestSeriesButtonLayout'>
                <Button variant="outlined" className='addTestSeriesButton' onClick={()=> {
                    setTestQuestionDetails({options:["","","",""]});
                    setOptionsCount(4);
                    setIsUpdateOrCreate(!isUpdateOrCreate)

                }} startIcon={<AddIcon />}>
                    Add a New Question
                </Button>
            </div>
            {isUpdateOrCreate && <div className='addQuestionLayout'>
                <h3>Add New Question</h3>
                <Editor value={testQuestionDetails.question} onChange={val=> setTestQuestionDetails({...testQuestionDetails,'question':val})}/>
                <div dangerouslySetInnerHTML={{ __html: testQuestionDetails.question}} />
                <div className="courseLayout">
                    <label> Question </label>
                    <input name='question' value={testQuestionDetails.question} onChange={handleInputChange} type="text"/>
                </div>
                {[...Array(optionsCount).keys()].map((item,index) => {
                    return <div className="courseLayout">
                        <label> {`option${index+1}`}</label>
                        <input name={`option${index+1}`} value={testQuestionDetails.options[index]} onChange={(e)=>handelOptionChange(e.target.value,index)} type="text"/>
                    </div>
                })}
                <div className='optionActionLayout'>
                    <button onClick={()=> {
                        setOptionsCount(optionsCount + 1);
                        let optionsCopy = testQuestionDetails.options;
                        optionsCopy = [...optionsCopy,''];
                        setTestQuestionDetails({...testQuestionDetails,options:optionsCopy})
                    }}>Add option</button>
                    <button onClick={()=> {
                        setOptionsCount(optionsCount - 1);
                        let optionsCopy = testQuestionDetails.options;
                        optionsCopy = optionsCopy.pop();
                        setTestQuestionDetails({...testQuestionDetails,options:optionsCopy})
                    }}>Remove option</button>
                </div>

                <div className="courseLayout">
                    <label> Answer</label>
                    <input name='answer' value={testQuestionDetails.answer} onChange={handleInputChange} type="text"/>
                </div>

                <div className="courseLayout">
                    <label> Description </label>
                    <textarea name='description' value={testQuestionDetails.description} onChange={handleInputChange} type="text"/>
                </div>
                <div className="add Question">
                    <button className='button' onClick={addQuestionToTest}>Add Question
                    </button>
                </div>
            </div>
            }
            <div className='allQuestionsLayout'>
                {
                    allQuestions.length ?
                        allQuestions.map((item,index) => {
                            return <QuestionCard data={item} qIndex={index} onDelete={onQuestionDelete}  onUpdateClick={onQuestionUpdate}/>
                        })
                        :
                        <div>Questions not found!, Please add new question</div>

                }
            </div>
        </div>
    );
};






export default QuestionAdding;

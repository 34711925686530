import React, {useEffect, useRef, useState} from 'react';
import QuestionCard from "../../../cms/test_series/test_series_questions/QuestionCard/QuestionCard";
import axios from "axios";
import {base_url} from "../../../../utils";
import './TestQuestionList.css'
import ReactTimerStopwatch from 'react-stopwatch-timer';
import {toast} from "react-hot-toast";
import TestQuestionCard from "../../components/TestQuestionCard/TestQuestionCard";
import Pagination from "../../components/Pagination/Pagination";
import SummaryDetails from "../../components/SummaryDetails/SummaryDetails";
import request from "../../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import Header from "../../../../webiste-components/header/Header";
import AlertDialog from "../../../../components/Dialog/Dialog";
import Countdown, {zeroPad} from "react-countdown";

const TestQuestionList = (props) => {

    const [isTestStarted,setIsTestStarted] = useState(false)
    const [showSummary,setShowSummary] = useState(false)
    const [allQuestions , setAllQuestions] = useState([]);
    const [currentQuestionIndex , setCurrentQuestionIndex] = useState(0);
    const [summaryDetails , setSummaryDetails] = useState({});
    const [timer, setTimer] = useState('00:00:00');
    const Ref = useRef(null);
    const [showDataNotFound,setShowDataNotFound] = useState(false);
    const [showConfirmPopup,setShowConfirmPopup] = useState(false);
    const [notSubscribed,setNotSubscribed] = useState(false);

    const dispatch  = useDispatch();

    function getAllQuestions() {
        request(`/question/all_question?series_id=${localStorage.getItem('test_series_id')}`,dispatch,).then(res => {
            if(res && res.length === 0){
                setShowDataNotFound(true)
                setNotSubscribed(true)
            }
            else{
                setShowDataNotFound(false)
            }
            setAllQuestions(res);
            console.log("all questions",res)
        }).catch(()=> {
            setNotSubscribed(true)
        })
    }


    function startTest() {
        setIsTestStarted(true);
        setTimeout(()=> {
            toast("1 Min Left... Test will close automatically after test time is up!",{duration:4000,position: 'bottom-right',})
        }, (localStorage.getItem('test_series_time') -1) * 1000 * 60);
        setTimeout(()=> {
            toast("Test time completed... Ending test",{duration:4000,position: 'bottom-right',})
            endTest();
        }, localStorage.getItem('test_series_time') * 1000*60);

        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        axios.post(`${base_url}/test_series/start_test`,{user_id:localStorage.getItem('userId'),test_series_id:parseInt(localStorage.getItem('test_series_id'))}).then(res => {
            toast('Test started!',{duration:4000,position: 'bottom-right',})
        }).catch(err=>{
            toast("Start test failed!",{duration:4000,position: 'bottom-right',})
        })
    }

    function updateAnswer (questionId,answer){
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        let allQuestionsCopy = [...allQuestions]
        let index = allQuestionsCopy.findIndex(item => item.id === questionId)
        allQuestionsCopy[index].answer = answer;
        setAllQuestions(allQuestionsCopy)
        axios.post(`${base_url}/test_series/session`,{user_id:localStorage.getItem('userId'),test_series_id:parseInt(localStorage.getItem('test_series_id')),question_id:questionId,answer:answer}).then(res => {
            // toast('Answer updated!',{duration:4000,position: 'bottom-right',})
            setIsTestStarted(true);
        }).catch(err=> {
            // toast("Start test failed!",{duration:4000,position: 'bottom-right',})
        })
    }


    function endTest (){
        setShowSummary(true)
        setIsTestStarted(false)
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        axios.post(`${base_url}/test_series/end_test`,{user_id:localStorage.getItem('userId'),test_series_id:parseInt(localStorage.getItem('test_series_id'))}).then(res => {
            // console.log('setSummaryDetails',res.data)
            setSummaryDetails(res.data)
            console.log('setSummaryDetails',summaryDetails)
            toast('Test Ended!, please wait for summary details',{duration:4000,position: 'bottom-right',})

        }).catch(err=>{
            toast("Start End failed!",{duration:4000,position: 'bottom-right',})
        })
    }

    useEffect(()=> {
        console.log('localStorage.getItem(\'test_series_time\')',localStorage.getItem('test_series_time'))
        getAllQuestions();
    },[]);


    const onNextClick = (val) => {
        if(currentQuestionIndex !==allQuestions.length){
            setCurrentQuestionIndex(currentQuestionIndex + 1)
        }
    };


    const onPrevClick = (val) => {
        if(currentQuestionIndex !==0){
            setCurrentQuestionIndex(currentQuestionIndex -1)
        }
    };

    const onSubmit = () =>{
        setShowConfirmPopup(true)
        // if(allQuestions.find(item => item.answer === '')){
        //     toast
        // }
        // else {

        // }
    };



    const onEndTest = () => {
        setShowConfirmPopup(false);
        endTest()
    }

    const renderer = ({ hours, minutes, seconds }) => (
        <span>
    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
    );

    return (
        <div style={{height:'100%',width:'100%',background:'white'}}>
            <Header/>
            {notSubscribed && <div>You are not subscribed to this test</div>}
            {showConfirmPopup && <AlertDialog onSave={onEndTest} handleClose={()=> setShowConfirmPopup(false)}/>}
            {isTestStarted && !notSubscribed && <div className='timer'>
                 <Countdown  intervalDelay={0} renderer={renderer} precision={3} date={Date.now() + (localStorage.getItem('test_series_time') * 1000*60)} />
            </div>}
            {!showSummary ? isTestStarted ? <div className='allQuestionsLayout'>
                    <div className='testDetailsLayout'>
                        <h2>{localStorage.getItem('test_series_name')}</h2>
                    </div>
                    <div className='timerLayout'>
                    </div>
                    {(allQuestions && allQuestions.length) && <TestQuestionCard data={allQuestions[currentQuestionIndex]} qIndex={currentQuestionIndex + 1}  onOptionClick={updateAnswer} />}
                    <Pagination onNextClick={onNextClick} onPrevClick={onPrevClick}  onSubmit={onSubmit} currentQuestionIndex={currentQuestionIndex} recordLength={allQuestions?.length || 0}/>
                    {/*<button className='endTest' onClick={endTest}>End Test</button>*/}
                </div> :
                <div className='testStartLayout'>
                    <div className='startTestWrapper'>
                        <input placeholder='Enter your email id'/>
                        <button className='startTest' onClick={()=>{startTest()}}>Start Test</button>
                    </div>
                </div> :<SummaryDetails data={summaryDetails}/>
            }
        </div>
    )
}

export default TestQuestionList;
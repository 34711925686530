import React, {useEffect, useState} from 'react';
import TagList from "../../components/TagList/TagList";
import axios from "axios";
import VideoCourseCard from "../../components/VideoCourseCard/VideoCourseCard";
import './VideoCourseList.css'
import {base_url} from "../../../../utils";
import {useDispatch} from "react-redux";
import request from "../../../../utils/apiRequest";

const VideoCourseList = ({activeTag}) => {

    const [videoCourses,setVideoCourses] = useState([])
    const dispatch = useDispatch();
    const [showDataNotFound,setShowDataNotFound] = useState(false);
    const [subscriptions,setSubscriptions] = useState(false);

    const getUserSubscriptions = (courses) => {
        console.log('localStorage.getItem(\'userId\')',localStorage.getItem('userId'))
        if(localStorage.getItem('userId') !== "null" ) {
            request(`/subscription/get_user_subscription?user_id=${localStorage.getItem('userId')}`, dispatch, {}, false).then(res => {
                if (res) {
                    setSubscriptions(res);
                    setVideoCourses(courses.map(item => ({
                        ...item,
                        is_selected: res.course_subscribe.findIndex(ele => item.id === ele) !== -1
                    })))
                }
            })
        }
    };

    useEffect(()=> {
        setShowDataNotFound(false);
        if(activeTag) getAllChapters()
    },[activeTag]);

    const getAllChapters = () => {
        setVideoCourses([]);
        request(`/course_list/by_tag?name=${activeTag}`,dispatch).then(res => {
            setVideoCourses(res);
            getUserSubscriptions(res);
            if(res && res.length === 0){
                setShowDataNotFound(true)
            }
            else{
                setShowDataNotFound(false)
            }
            console.log("res.data",res)
        })
    };

    return (
        <div className='videoWrapper'>
            {showDataNotFound && <h3 className='notFound'>No Data Found !!</h3>}
            {console.log("video courses",videoCourses)}
            {videoCourses.map(video => {
               return <VideoCourseCard data={video} type={'Videos'}/>
            })}

        </div>
    );
};

export default VideoCourseList;

import React from 'react';
import './Popup.css'

const Popup = ({body,footer,header,onCancel,onSave}) => {
    return (
        <div className='modal'><div className='popupWrapper'>
            <span onClick={onCancel} className="close">&times;</span>
            <div className='popupHeader'><p className='headerText'>{header}</p></div>
            <div className='popupBody'>{body}</div>
            <div className='popupFooter'>
                <button className='cancelBtn' onClick={onCancel}>
                    Cancel
                </button>
                <button className='saveBtn' onClick={onSave}>
                    Yes
                </button>
            </div>
        </div>
        </div>
    );
};

export default Popup;
import React, {useState} from 'react';
import Notes from "./notes/Notes";
import Chapters from "./chapters/Chapters";

const NotesCms = () => {

    const [currentView,setCurrentView] = useState("Notes")

    return (
        <div style={{height:'100%'}}>
            {currentView === 'Notes' ?
                <Notes setCurrentView={setCurrentView}/>
                :
                <Chapters setCurrentView={setCurrentView} />
            }
        </div>
    );
};

export default NotesCms;
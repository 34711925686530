import React from 'react';
import styles from './Courses.module.css'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import classNames from "classnames";


const CourseCard = (props) => {
    return (
        <div className={styles.cardWrapper}>
            <h1 className={classNames(styles.courseTitle, props.titleStyle)}>{props.data.title}</h1>
            <div style={{padding:20,fontWeight:500}}><p className={styles.courseDescription1}>{props.data.description1}</p>
            <p className={styles.courseDescription2}>{props.data.description2}</p>
            <p className={styles.courseDescription2}>{props.data.description3}</p>
            {props.data.price && <div className={styles.priceWrapper}>
                <CurrencyRupeeIcon/>
                <p>{props.data.price}</p>
            </div>}
            {/*<a className={styles.contactButton} href={'https://api.whatsapp.com/send?phone=+917996120669'}>*/}
            {/*    <WhatsAppIcon className={styles.media} /> Contact 7996120669 </a>*/}
            </div>
        </div>
    );
};

export default CourseCard;

import React, {useEffect, useState} from 'react';
import './VideoCourse.css'
import axios from "axios";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    RadioGroup,
    Select,
    Radio,
    SelectChangeEvent
} from "@mui/material";
import VideoCourseCard from "./coursecard/VideoCourseCard";
import Chapters from "./VideoChapters/chapter";
import {base_url} from "../../../utils";
import { useDispatch } from "react-redux";
import {toast} from "react-hot-toast";
import request from "../../../utils/apiRequest";
import AlertDialog from "../../../components/Dialog/Dialog";

const Courses = () => {


    const [courseDetails , setCourseDetails] = useState({});
    const [courseList , setCourseList] = useState([]);
    const [activeTag , setActiveTag] = useState([]);
    const [currentCourse , setCurrentCourse] = useState([]);
    const [courseTags,setCourseTags] = useState([]);
    const [isAddOrUpdateCourse , setIsAddOrUpdateCourse] = useState(false);
    const dispatch = useDispatch();
    const [showDataNotFound,setShowDataNotFound] = useState(false);


    const [isAddOrUpdateChapter , setIsAddOrUpdateChapter] = useState(false);
    const [showDeleteAlert , setShowDeleteAlert] = useState(false);


    useEffect(()=> {
        console.log("Video courses");
        getAllVideoCourses();
        getAllVideoCourseTags();
    },[]);


    function onCourseClick(course) {
        setCurrentCourse(course);
        setIsAddOrUpdateChapter(true);
    }

    const getAllVideoCourses = () => {
        let res = request(`/course_list/all`,dispatch)
        res.then(res => {
            setCourseList(res);
            if(res && res.length === 0){
                setShowDataNotFound(true)
            }
            else{
                setShowDataNotFound(false)
            }
            console.log("res",res)
            dispatch({type: "DECREMENT",step: 1})
        })
    };

    const getAllVideoCourseTags = () => {
        dispatch({type: "INCREMENT", step: 1})
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        request('/tags?key=CourseList',dispatch).then(res => {
            let tags = res.map(item => item.name)
            setCourseTags(tags);
            setActiveTag(tags[0])
            dispatch({type: "DECREMENT",step: 1})
            setCourseDetails({...courseDetails,tag:tags[0]});
            console.log("getAllTags",tags)
        })
    };

    function courseData(e) {
        let newData = {...courseDetails};
        if(e.target.name === 'amount'){
            newData[e.target.name] = parseInt(e.target.value)
        }
        else{
            newData[e.target.name] = e.target.value
        }

        setCourseDetails(newData);
        console.log(newData)
    }

    function onDeleteCourseClick(course) {
        setShowDeleteAlert(true);
        setCourseDetails(course)
    }

    const deleteCourse = () => {
        request(`/course_list/delete?course_list_id=${courseDetails.id}`,dispatch,{method:"DELETE"}).then(res => {
            setShowDeleteAlert(false)
            getAllVideoCourses();
            console.log("Delete")})

    };

    const handleChange = (e: SelectChangeEvent) => {
        setCourseDetails({...courseDetails,tags:e.target.value});
    };

    function onUpdateCourseClick(course) {
        console.log('course',course);
        setCourseDetails(course);
        setIsAddOrUpdateCourse(true)
    }

    function addOrUpdateCourse(e) {
        if(!courseDetails?.id){
            const url =`/course_list/new`;
            e.preventDefault();
            request(url,dispatch,{
                method:"POST",
                body: {
                    "name": courseDetails.name,
                    "description": courseDetails.description,
                    "amount": courseDetails.amount,
                    "is_active": true,
                    "duration": 60,
                    'tags': courseDetails.tags,
                    'is_mains': true,
                }
            }).then(res=>{
                console.log('response',res);
                setIsAddOrUpdateCourse(false)
                // toast('Updated course successfully ',{duration:4000,position: 'bottom-right',});
                getAllVideoCourses()
            }).catch((e)=> {
                toast('Creating Course Failed... Please check with Dev',{duration:4000,position: 'bottom-right',});
            })
        }
        else {
            const url =`/course_list/update`;
            request(url,dispatch,{
                method:"PUT",
                body:{
                    "name": courseDetails.name,
                    "description": courseDetails.description,
                    "amount": courseDetails.amount,
                    "is_active": true,
                    "duration": 60,
                    'tags':courseDetails.tags,
                    id:courseDetails?.id},
            },).then(res=>{
                setIsAddOrUpdateCourse(false)
                // toast('Added course successfully ',{duration:4000,position: 'bottom-right',});
                getAllVideoCourses()})
                .catch((e)=> {
                    toast(e,{duration:4000,position: 'bottom-right',});
                })
        }
        getAllVideoCourses();
    }

    function getAddCourseView (){
        return  <div className='courseWrapper'>
            <div className={'addTestSeriesButtonLayout'}>
                <Button variant="outlined" className={'addCourseButton'} onClick={()=>{
                    setCourseDetails({})
                    setIsAddOrUpdateCourse(!isAddOrUpdateCourse)}} startIcon={<AddIcon />}>
                    Add New Course
                </Button>
            </div>
            {isAddOrUpdateCourse &&
            <div className='addCourse'>
                <h3>Add Video Course Details</h3>
                <div className="courseLayout">
                    <label>Name </label>
                    <input name='name' value={courseDetails.name} onChange={courseData} type="text" />
                </div>
                <div className="courseLayout">
                    <label>Description </label>
                    <textarea name='description' value={courseDetails.description} onChange={courseData} type="text" />
                </div>
                <div className="courseLayout">
                    <label>Price</label>
                    <input name ='amount' value={courseDetails.amount}  onChange={courseData} type="text" />
                </div>
                <div className="courseLayout">
                    <label>Create New Tag </label>
                    <input name='tags' value={courseDetails.tags} onChange={courseData} type="text" />
                </div>

                {courseTags?.length ? <FormControl fullWidth><Select
                    style={{marginTop:20,display:'inline-block'}}
                    value={courseDetails.tags}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {courseTags.map(tag => {
                        return <MenuItem key={tag?.id}  style={{width:300}} value={tag}>{tag}</MenuItem>
                    })}
                </Select></FormControl> : null}
                <button className={'testSeriesButton'} onClick= { addOrUpdateCourse}>{courseDetails.id ? "Update" +
                    " Course" : "Add Course"}</button>
            </div>}
            <div className='listWrapper'>
                {courseList?.map(course => {
                    return <VideoCourseCard data={course} onCourseClick={onCourseClick} onUpdateClick={onUpdateCourseClick} onDelete={onDeleteCourseClick}/>
                })}
            </div>
        </div>
    }

    return (<div className='wrapper'>
            {showDataNotFound && <h3 className='notFound'>No Data Found !!</h3>}
            {showDeleteAlert && <AlertDialog onSave={deleteCourse} handleClose={()=> {
                setShowDeleteAlert(false);
                console.log('handle close');
            }}/>}
            {!isAddOrUpdateChapter ? getAddCourseView() : <Chapters currentCourse={currentCourse}/>}
        </div>


    );
};

export default Courses;

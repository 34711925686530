import React, {useEffect, useState} from 'react';
import axios from "axios";
import TestSeriesCard from "../test_series_card/TestSeriesCard";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import '../test_series_card/TestSeriesCard.css'
import {base_url} from "../../../../utils";
import request from "../../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import {toast} from "react-hot-toast";


const TestList = (props) => {
    let dispatch = useDispatch()

    const [testList, setTestList] = useState([]);
    const [isUpdateOrCreate, setIsUpdateOrCreate] = useState(false);
    const [testDetails, setTestDetails] = useState({name: " " ,description: " ",tags:'',id:''});
    const [currentTestDetails, setCurrentTestDetails] = useState({name: " " ,description: " ",tags:'',id:''});


    useEffect(()=> {
        getAllTestsInSeries();
    },[]);


    const onTestDelete  = (test) => {
        request(`/test_series/delete?series_id=${test.id}`,dispatch,{method:'DELETE'}).then(res => {
            getAllTestsInSeries();
            console.log("Delete")})
    };

    const onTestUpdateClick = (test) => {
        console.log('test',test);
        setTestDetails(test);
        setIsUpdateOrCreate(true)
    };

    const onTestClick  = (test) => {
        console.log('test',test);
        props.onAddQuestionClick(test);
        setCurrentTestDetails(test);
    };

    function getAllTestsInSeries(){
        request(`/test_series/all?series_list_id=${props.currentTestSeries.id}`,dispatch).then(res => {
            setTestList(res);
            console.log("getAllTestSeries",res)
        })
    }

    function addTestToSeries(e) {
        e.preventDefault();
        let testSeriesData = {
            name: testDetails.name,
            description: testDetails.description,
            time:testDetails.time,
            test_series_list_id:props.currentTestSeries.id,
        };
        if(testDetails.id){
            testSeriesData = {...testSeriesData,id:testDetails.id};
            console.log('testSeriesData',testSeriesData);
            request( `/test_series/update`,dispatch,{body:testSeriesData,method:'PUT'})
                .then(res=>{
                    getAllTestsInSeries();
                    setTestDetails({})
                    setIsUpdateOrCreate(false)
                })
        }
        else{
            request( `/test_series/new`,dispatch,{body:testSeriesData,method:"POST"}).then(res=> {
                    getAllTestsInSeries()
                setTestDetails({})
                setIsUpdateOrCreate(false)
                })
        }
    }

    function handleChange(e) {
        setTestDetails({...testDetails,[e.target.name]:e.target.value});
    }

    return (
        <div className={'testSeriesDetailsWrapper'}>
            <div className='addTestSeriesButtonLayout'>
                <Button variant="outlined" className='addTestSeriesButton' onClick={()=> setIsUpdateOrCreate(!isUpdateOrCreate)} startIcon={<AddIcon />}>
                    Add a New Test to {props.currentTestSeries.name}
                </Button>
            </div>
            {isUpdateOrCreate && <div>
                <div className='testSeriesInfo'>
                    <h5>Test Series Details</h5>
                    <div className="testSeriesLayout">
                        <label>Name </label>
                        <input name='name' value={testDetails.name} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Description </label>
                        <input name='description' value={testDetails.description} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Time in minutes </label>
                        <input name='time' value={testDetails.time} onChange={handleChange} type="nuber" />
                    </div>
                    <button className={'testSeriesButton'} onClick={addTestToSeries}>save</button>
                </div>
            </div>}
            <div className='listWrapper'>
                {testList?.length ? testList.map(testSeries => {
                    return <TestSeriesCard data={testSeries} onDelete={onTestDelete} onUpdateClick={onTestUpdateClick} onTestSeriesClick={onTestClick}  />
                }) : <div>Tests not found</div>}
            </div>
        </div>
    );
};

export default TestList;

import React, {useEffect, useState} from 'react';
import './TestSeries.css';
import axios from "axios";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import  { SelectChangeEvent } from '@mui/material/Select';
import {MenuItem, Select} from "@mui/material";
import TestSeriesCard from "./test_series_card/TestSeriesCard";
import FormControl from "@mui/material/FormControl";
import TestList from "./TestList/TestList";
import QuestionAdding from "./test_series_questions/QuestionAdding";
import {base_url} from "../../../utils";
import request from "../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import {toast} from "react-hot-toast";
import AlertDialog from "../../../components/Dialog/Dialog";

function TestSeries(props) {

    let dispatch = useDispatch();

    //Conditions to show views
    const [isUpdateOrCreate, setIsUpdateOrCreate] = useState(false);
    const [updateOrAddTestToSeries, setUpdateOrAddTestToSeries] = useState(false);
    const [isAddTestQuestion, setIsAddTestQuestion] = useState(false);

    const [showDeleteTestSeriesAlert, setShowDeleteTestSeriesAlert] = useState(false);


    //test series details
    const [testSeriesDetails, setTestSeriesDetails] = useState({name: " ", description: " ", price: " ",tags:''});
    const [allTestSeries, setAllTestSeries] = useState([]);
    const [testSeriesTags,setTestSeriesTags] = useState([]);
    const [currentSeries,setCurrentSeries] = useState({});
    const [currentTestId,setCurrentTestId] = useState({});

    useEffect(()=>{
        getAllTestSeries();
        getAllTags();
    },[]);


    const onTestSeriesClick = (series) => {
        setCurrentSeries(series);
        setUpdateOrAddTestToSeries(true)
    };

    const onTestSeriesUpdateClick = (series) => {
        console.log('series',series);
        setTestSeriesDetails(series);
        setIsUpdateOrCreate(true)
    };

    function getAllTestSeries(){
        request(`/test_series_list/all`,dispatch).then(res => {
            setAllTestSeries(res);
            console.log("getAllTestSeries",res)
        })
    }

    const handleChange = (e: SelectChangeEvent) => {
        setTestSeriesDetails({...testSeriesDetails,tags:e.target.value});
    };

    const onTestSeriesDelete = (series) => {
        setCurrentSeries(series);
        setShowDeleteTestSeriesAlert(true)
    };

    const deleteTestSeries = () => {
        request(`/test_series_list/delete?series_list_id=${currentSeries.id}`,dispatch,{method:'DELETE'}).then(res => {
            console.log('Delete',res);
            getAllTestSeries();
            setShowDeleteTestSeriesAlert(false);
            console.log("Delete")
        })
    };

    function getAllTags(){
        request(`/tags?key=TestSeriesList`,dispatch).then(res => {
            let tags = res.map(item => item.name)
            setTestSeriesTags(tags);
            setTestSeriesDetails({...testSeriesDetails,tag:tags[0]});
            console.log("getAllTags",tags)
        })
    }

    function handleInputChange(e) {
        let newData = {...testSeriesDetails};
        if(e.target.name === 'price'){
            newData[e.target.name] = parseInt(e.target.value);
        }
        else{
            newData[e.target.name] = e.target.value;
        }
        setTestSeriesDetails(newData);
    }

    function addTestSeriesOrTest(e) {
        e.preventDefault();
        let testSeriesData = {
            name: testSeriesDetails.name,
            description: testSeriesDetails.description,
            tags:testSeriesDetails.tags,
            price:testSeriesDetails.price,
            is_mains:true,
        };
        if(testSeriesDetails.id ){
            testSeriesData = {...testSeriesData,id:testSeriesDetails.id}
            request( `/test_series_list/update`,dispatch,{body:testSeriesData,method:'PUT'}).then(res=>{
                // toast('Updated Test Series successfully ',{duration:4000,position: 'bottom-right',});
                setIsUpdateOrCreate(false)
                getAllTestSeries()})
        }
        else{
            request( `/test_series_list/new`,dispatch,{body:testSeriesData, method:'POST'}).then(res=> {
                // toast('Added Test Series successfully ',{duration:4000,position: 'bottom-right',});
                setIsUpdateOrCreate(false)
                console.log(res);getAllTestSeries()
            })
        }
    }


    function getTestSeriesView () {
        return <div className={'testSeriesDetailsWrapper'}>
            <div className='addTestSeriesButtonLayout'>
                <Button variant="outlined" className='addTestSeriesButton' onClick={()=> setIsUpdateOrCreate(!isUpdateOrCreate)} startIcon={<AddIcon />}>
                    Add New TestSeries
                </Button>
            </div>

            {showDeleteTestSeriesAlert && <AlertDialog onSave={deleteTestSeries} handleClose={()=> setShowDeleteTestSeriesAlert(false)}/>}
            {isUpdateOrCreate && <div>
                <div className='testSeriesInfo'>
                    <h5>Test Series Details</h5>
                    <div className="testSeriesLayout">
                        <label>Name </label>
                        <input name='name' value={testSeriesDetails.name} onChange={handleInputChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Description </label>
                        <textarea name='description' value={testSeriesDetails.description} onChange={handleInputChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Price </label>
                        <input name='price' value={testSeriesDetails.price} onChange={handleInputChange} type="number" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Add New Tag </label>
                        <input name='tags' value={testSeriesDetails.tags} onChange={handleInputChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                    {testSeriesTags?.length ?<FormControl fullWidth>
                        <label>Select Existing Tag </label>
                        <Select
                            style={{marginTop:20}}
                            value={testSeriesDetails.tags}
                            onChange={handleChange}
                            displayEmpty
                            name='tag'
                            inputProps={{ 'aria-label': 'Without label' }}>
                            {testSeriesTags.map(tag => {
                                return <MenuItem key={tag?.id} style={{width:300}} value={tag} >{tag}</MenuItem>
                            })}
                        </Select>
                    </FormControl> : null}
                    </div>
                    <button className='testSeriesButton' onClick={addTestSeriesOrTest}>{!testSeriesDetails.id ? 'SAVE' : "UPDATE"}</button>
                </div>
            </div>}
            <div className='listWrapper'>
                {allTestSeries.map(testSeries => {
                    return <TestSeriesCard data={testSeries} onDelete={onTestSeriesDelete} onUpdateClick={onTestSeriesUpdateClick} onTestSeriesClick={onTestSeriesClick}  />
                })}
            </div>
        </div>
    }

    return (<div className='wrapper'>
            {isAddTestQuestion ? <QuestionAdding test_series_id={currentTestId} currentTestId={currentTestId}/> :
                updateOrAddTestToSeries ?
                    <TestList  onAddQuestionClick={(test)=>{
                        setCurrentTestId(test.id);
                        setIsAddTestQuestion(true)}
                    } currentTestSeries={currentSeries} />
                    :
                    getTestSeriesView()
            }
        </div>


    );
}

export default TestSeries;

import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TestSeriesCard from "../../test_series/test_series_card/TestSeriesCard";
import ChapterCard from "../../notes_cms/chapters/ChapterCard/ChapterCard";
import {base_url} from "../../../../utils";
import request from "../../../../utils/apiRequest";
import {useDispatch} from "react-redux";


const NotesChapters = (props) => {

    const [chaptersList, setChaptersList] = useState([]);
    const [isUpdateOrCreate, setIsUpdateOrCreate] = useState(false);
    const [chapterDetails, setChapterDetails] = useState({});
    const [currentChapter, setCurrentChapter] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        getAllChaptersInNotes();
    },[]);


    const onChapterDelete  = (chapter) => {
        request(`/notes/delete?course_id=${chapter.id}`,dispatch,{method:'delete'}).then(res => {getAllChaptersInNotes();console.log("Delete")})
    };

    const onChapterUpdateClick = (chapter) => {
        setChapterDetails(chapter);
        setIsUpdateOrCreate(true);
    };

    const onChapterClick  = () => {

    };

    function getAllChaptersInNotes(){
        request(`/notes/all?notes_list_id=${props.currentCourse.id}`,dispatch).then(res => {
            setChaptersList(res);
            console.log("getAllTestSeries",res)
        })
    }

    function addChapterToNotes(e) {
        let chapterData = {
            description:chapterDetails.description,
            name: chapterDetails.name,
            reading_time:chapterDetails.reading_time,
            is_video:true,
            notes_list_id:props.currentCourse.id,
            bucket_name:'aruna-ias-academy-courses',
            resource_name:chapterDetails.resource_name,
        };
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('access_token');
        if(chapterDetails.id ){
            chapterData = {...chapterData,id:chapterDetails.id}
            request( `/notes/update`,dispatch,{body:chapterData,method:"PUT"}).then(res=>{
              getAllChaptersInNotes();
                setIsUpdateOrCreate(false)
            });
        }
        else{
            request( `/notes/new`,dispatch,{body:chapterData,method:"POST"}).then(res=> {console.log(res);getAllChaptersInNotes()})
        }
    }


    function handleChange(e) {
        setChapterDetails({...chapterDetails,[e.target.name]:e.target.value});
    }

    return (
        <div className={'testSeriesDetailsWrapper'}>
            <div className='addTestSeriesButtonLayout'>
                <Button variant="outlined" className='addTestSeriesButton' onClick={()=> setIsUpdateOrCreate(!isUpdateOrCreate)} startIcon={<AddIcon />}>
                    Add a New Chapter To {props.currentCourse.course_name}
                </Button>
            </div>
            {isUpdateOrCreate && <div>
                <div className='testSeriesInfo'>
                    <h5>Test Series Details</h5>
                    <div className="testSeriesLayout">
                        <label>Name </label>
                        <input name='name' value={chapterDetails.name} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Description </label>
                        <input name='description' value={chapterDetails.description} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Reading time </label>
                        <input name='reading_time' value={chapterDetails.reading_time} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>bucket_name </label>
                        <input name='bucket_name' value={chapterDetails.bucket_name} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>resource_name </label>
                        <input name='resource_name' value={chapterDetails.resource_name} onChange={handleChange} type="text" />
                    </div>
                    <button className={'testSeriesButton'} onClick={addChapterToNotes}>save</button>
                </div>
            </div>}
            <div className='listWrapper'>
                {chaptersList.map(testSeries => {
                    return <ChapterCard data={testSeries} onDelete={onChapterDelete} onUpdateClick={onChapterUpdateClick} onTestSeriesClick={onChapterClick}  />
                })}
            </div>
        </div>
    );
};

export default NotesChapters;

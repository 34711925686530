import React from 'react';
import './TagList.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TagList = ({tags,currentTag,setCurrentTag}) => {
    return (
        <div className='tagsWrapper'>
            {tags.map(tag => {
                return <div onClick={()=> setCurrentTag(tag)} className={`tagItemWrapper ${currentTag === tag ? 'active' : ''}`}>
                    <p  className='tag'>{tag}</p>
                    <ArrowForwardIosIcon color="gray"/>
                </div>
            })}
        </div>
    );
};

export default TagList;

import React, {useState} from 'react';
import CourseAdding from "../video_course_cms/VideoCourse";
import TestSeries from "../test_series/TestSeries";
import {Link, Route, Routes} from "react-router-dom";
import './mainCms.css'
import Courses from "../video_course_cms/VideoCourse";
import NotesCms from "../notes_cms/NotesCms";
import CurrentAffairs from "../current_affairs/current_affairs";
import Header from "../../../webiste-components/header/Header";
import UserPermissions from "../UserPermissions/UserPermissions";
import { useNavigate } from 'react-router-dom'
import ResourcesNotes from "../ResourceCMS/ResourceNotes/ResourcesNotes";
import ResourceCms from "../ResourceCMS/ResourceCMS";
import ScholarshipTestCMS from "../ScholarshipTestCMS/ScholarshipTestCMS";

function MainCms(props) {
    const navigate = useNavigate();

    const [activeTab,setActiveTab] = useState('testSeries')

    const pushToScreen = (link,name) => {
        navigate(link)
        setActiveTab(name)
        setTimeout(()=> {
            window.location.reload(false);
        },0)
    };

    return (
        <div style={{width:'100%',height:'100%'}}>
            <Header/>
            <div className='tabs'>
                <button className={`link ${window.location.href.includes('testSeries') ? 'active': '' }`}  onClick={()=> {
                    pushToScreen('/admin/testSeries')
                }}>Test Series</button>
                <button className={`link ${window.location.href.includes('course') ? 'active': '' }`}  onClick={()=> {
                    pushToScreen('/admin/course','videoCourses')
                }}>Video Courses</button>
                <button className={`link ${window.location.href.includes('notes') ? 'active': '' }`}   onClick={()=> {
                    pushToScreen('/admin/notes','notes')
                }}>Notes</button>
                {/*<Link className='link' to="/admin/promotions">Promotions</Link>*/}
                <button className={`link ${window.location.href.includes('user_permissions') ? 'active': '' }`}  onClick={()=> {
                    pushToScreen('/admin/user_permissions','subscriptions')
                }}>Subscription</button>
                <button className={`link ${window.location.href.includes('resources') ? 'active': '' }`}  onClick={()=> {
                    pushToScreen('/admin/resources','resources')
                }}>Resources notes</button>
                <button className={`link ${window.location.href.includes('resources') ? 'active': '' }`}  onClick={()=> {
                    pushToScreen('/admin/scholarship_tests','resources')
                }}>scholarship tests</button>
            </div>
            <Routes>
                <Route path="/testSeries" element={<TestSeries/>}/>
                <Route path="/course" element={<Courses/>}/>
                <Route path="/notes" element={<NotesCms/>}/>
                <Route path="/promotions" element={<CurrentAffairs/>}/>
                <Route path="/user_permissions" element={<UserPermissions/>}/>
                <Route path="/resources" element={<ResourceCms/>}/>
                <Route path="/scholarship_tests" element={<ScholarshipTestCMS/>}/>
            </Routes>
        </div>
    );
}

export default MainCms;

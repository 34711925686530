import React from 'react';
import './SummaryQuestionDetails.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SummaryQuestionDetails = ({data,qIndex,onOptionClick}) => {
    return (
        <div className='sQuestionCardWrapper'>
            <div dangerouslySetInnerHTML={{ __html: data?.question}} />
            {/*<p >{qIndex}. {data?.question}</p>*/}
            {
                data.options.map((item,index) => {
                    return <div className='optionLayout' onClick={()=> onOptionClick(data.id,item)} >
                        <button className='optionBullet'>{index +1}. </button>
                        <p className='optionAnswer'>{item}</p>
                        {/*{data.answer === item  && <CheckCircleIcon style={{color:'green',marginLeft:'10px'}}/> }*/}
                    </div>
                })
            }

            <div className={'answerLayout'}>
                <h3>Your Answer : <span style={{fontWeight:400}}>{data?.user_answer || "--"}</span></h3>
                <h3>Actual Answer : <span style={{fontWeight:400}}>{data?.answer || "--"}</span></h3>
                <h3>Description : <span style={{fontWeight:400}}>{data?.description || "--"}</span></h3>
            </div>

            {/*<button className="button" onClick={(e)=> {e.stopPropagation();onUpdateClick(data)}}>Submit</button>*/}
        </div>
    );
};

export default SummaryQuestionDetails;

import React, {useEffect, useState} from 'react';
import TagList from "../../components/TagList/TagList";
import axios from "axios";
import VideoCourseCard from "../../components/VideoCourseCard/VideoCourseCard";
import './TestSeriesList.css'
import NotesCard from "../../components/NotesCard/NotesCard";
import {base_url} from "../../../../utils";
import {useDispatch} from "react-redux";
import request from "../../../../utils/apiRequest";

const TestSeriesList = ({currentTag}) => {

    const dispatch = useDispatch()
    const [testSeries,setTestSeries] = useState([])
    const [showDataNotFound,setShowDataNotFound] = useState(false);

    const getUserSubscriptions = (testSeries) => {
        if(localStorage.getItem('userId') !== "null") {
            request(`/subscription/get_user_subscription?user_id=${localStorage.getItem('userId')}`, dispatch).then(res => {
                if (res) {
                    console.log('testSeries', testSeries);
                    setTestSeries(testSeries.map(item => ({
                        ...item,
                        is_selected: res.test_subscribe.findIndex(ele => item.id === ele) !== -1
                    })))
                }
                console.log("all users", res)
            })
        }
    };

    useEffect(() => {
        getAllChapters();
    },[currentTag])

    const getAllChapters = () => {
        request(`/test_series_list/by_tag?name=${currentTag}`,dispatch).then(res => {
            setTestSeries(res);
            getUserSubscriptions(res);
            if(res && res.length === 0){
                setShowDataNotFound(true)
            }
            else{
                setShowDataNotFound(false)
            }
            console.log("test series",res)
        })
    };

    return (
        <div className='videoWrapper'>
            {showDataNotFound && <h2 className='notFound'>No Data Found !!</h2>}
            {testSeries.map(testSeriesItem => {
                return <VideoCourseCard data={testSeriesItem} type='TestSeries'/>
            })}
        </div>
    );
};

export default TestSeriesList;

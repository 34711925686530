import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import AddIcon from "@mui/material/SvgIcon/SvgIcon";
import Editor from "../../user/components/Editor";
import QuestionCard from "../test_series/test_series_questions/QuestionCard/QuestionCard";
import * as firebase from 'firebase'
import {toast} from "react-hot-toast";

const ScholarshipTestCMS = () => {

    const [testQuestionDetails, setTestQuestionDetails] = useState({options:["","","",""]});
    const [allQuestions , setAllQuestions] = useState([]);
    const [isUpdateOrCreate, setIsUpdateOrCreate] = useState(false);
    const [optionsCount,setOptionsCount] = useState(4);

    useEffect(() => {
        getAllQuestions()
    },[]);

    function getAllQuestions() {
        firebase.firestore().collection('scholarship_tests_1').get().then((querySnapshot) => {
            let scholarshipTestQns = [];
            querySnapshot.forEach((doc) => {
                console.log("qns id",doc.id);
                let qns = doc.data();
                qns.id = doc.id;
                scholarshipTestQns.push(qns);
            });
            console.log("scholarshipTestQuens",scholarshipTestQns)
            setAllQuestions(scholarshipTestQns);
        });
    }

    // const config = useMemo(()=>
    //         ({
    //             readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    //             placeholder: props?.placeholder || 'Start typings...'
    //         }),
    //     [props?.placeholder]
    // );

    function handleInputChange(e) {
        setTestQuestionDetails({...testQuestionDetails,[e.target.name]:e.target.value});
    }

    function handelOptionChange(value,index) {
        let options = testQuestionDetails.options;
        options[index] = value;
        setTestQuestionDetails({...testQuestionDetails,options});
        console.log("options",testQuestionDetails)
    }

    function addQuestionToTest(e) {
        e.preventDefault();
        let testQuestionData = {
            question: testQuestionDetails.question,
            options: testQuestionDetails.options,
            answer: testQuestionDetails.answer,
            description: testQuestionDetails.description
        };
        console.log("testQuestionData",testQuestionData);
        if(!testQuestionDetails.id) {
            firebase.firestore().collection('scholarship_tests_1').add(testQuestionData).then((docRef) => {
                console.log("testQuestionData ID: ", docRef.id);
                toast('Add question success!',{duration:4000,position: 'bottom-right',})
                getAllQuestions()
            })
                .catch((error) => {
                    toast('Error adding test question!',{duration:4000,position: 'bottom-right',})
                    console.error("Error adding test question: ", error);
                });
        }
        else{
            firebase.firestore().collection('scholarship_tests_1').doc(testQuestionDetails.id).update(testQuestionData).then((docRef) => {
                console.log("testQuestionData  update success: ");
                toast('Update question success!',{duration:4000,position: 'bottom-right',})
                getAllQuestions()
            })
                .catch((error) => {
                    toast('Update question failed!',{duration:4000,position: 'bottom-right',})
                    console.error("Error adding test question: ", error);
                });
        }
    }

    const onQuestionDelete = (question) => {
        firebase.firestore().collection('scholarship_tests_1').doc(question.id).delete().then((docRef) => {
            console.log("Deleted question ");
            toast('Deleted question successfully!',{duration:4000,position: 'bottom-right',})
            getAllQuestions()
        })
            .catch((error) => {
                toast('Deleted question failed!',{duration:4000,position: 'bottom-right',})
                console.error("Error deleting test question: ", error);
            });
    };

    const onQuestionUpdate = (questionDetails) => {
        setTestQuestionDetails(questionDetails)
        setIsUpdateOrCreate(true)
    };

    return (
        <div className='testSeriesDetailsWrapper'>
            <div className='addTestSeriesButtonLayout'>
                <Button variant="outlined" className='addTestSeriesButton' onClick={()=> {
                    setTestQuestionDetails({options:["","","",""]});
                    setOptionsCount(4);
                    setIsUpdateOrCreate(!isUpdateOrCreate)

                }} startIcon={<AddIcon />}>
                    Add a New Question
                </Button>
            </div>
            {isUpdateOrCreate && <div className='addQuestionLayout'>
                <h3>Add New Question</h3>
                <Editor value={testQuestionDetails.question} onChange={val=> setTestQuestionDetails({...testQuestionDetails,'question':val})}/>
                <div dangerouslySetInnerHTML={{ __html: testQuestionDetails.question}} />
                <div className="courseLayout">
                    <label> Question </label>
                    <input name='question' value={testQuestionDetails.question} onChange={handleInputChange} type="text"/>
                </div>
                {[...Array(optionsCount).keys()].map((item,index) => {
                    return <div className="courseLayout">
                        <label> {`option${index+1}`}</label>
                        <input name={`option${index+1}`} value={testQuestionDetails.options[index]} onChange={(e)=>handelOptionChange(e.target.value,index)} type="text"/>
                    </div>
                })}
                <div className='optionActionLayout'>
                    <button onClick={()=> {
                        setOptionsCount(optionsCount + 1);
                        let optionsCopy = testQuestionDetails.options;
                        optionsCopy = [...optionsCopy,''];
                        setTestQuestionDetails({...testQuestionDetails,options:optionsCopy})
                    }}>Add option</button>
                    <button onClick={()=> {
                        setOptionsCount(optionsCount - 1);
                        let optionsCopy = testQuestionDetails.options;
                        optionsCopy = optionsCopy.pop();
                        setTestQuestionDetails({...testQuestionDetails,options:optionsCopy})
                    }}>Remove option</button>
                </div>

                <div className="courseLayout">
                    <label> Answer</label>
                    <input name='answer' value={testQuestionDetails.answer} onChange={handleInputChange} type="text"/>
                </div>

                <div className="courseLayout">
                    <label> Description </label>
                    <textarea name='description' value={testQuestionDetails.description} onChange={handleInputChange} type="text"/>
                </div>
                <div className="add Question">
                    <button className='button' onClick={addQuestionToTest}>Add Question
                    </button>
                </div>
            </div>
            }
            <div className='allQuestionsLayout'>
                {
                    allQuestions.length ?
                        allQuestions.map((item,index) => {
                            return <QuestionCard data={item} key={index} qIndex={index} onDelete={onQuestionDelete}  onUpdateClick={onQuestionUpdate}/>
                        })
                        :
                        <div>Questions not found!, Please add new question</div>

                }
            </div>
        </div>
    );
};

export default ScholarshipTestCMS;
import React from 'react';
import './Pagination.css'

const Pagination = ({onNextClick,onPrevClick,onSubmit,currentQuestionIndex,recordLength}) => {
    return (
        <div className='paginationLayout'>
            {currentQuestionIndex !== 0 && <button className='pagBtn' onClick={onPrevClick}>Previous</button>}
            <button className='pagBtn' onClick={onSubmit}>Submit</button>
            {(currentQuestionIndex !== recordLength-1)  && <button  className='pagBtn' onClick={onNextClick}>Next</button>}
        </div>
    );
};

export default Pagination;
import React, {useEffect, useState} from 'react';
import TagList from "../../components/TagList/TagList";
import axios from "axios";
import VideoCourseCard from "../../components/VideoCourseCard/VideoCourseCard";
import './NotesList.css'
import NotesCard from "../../components/NotesCard/NotesCard";
import {base_url} from "../../../../utils";
import request from "../../../../utils/apiRequest";
import {useDispatch} from "react-redux";

const NotesList = ({currentTag}) => {

    const dispatch = useDispatch();
    const [showDataNotFound,setShowDataNotFound] = useState(false);
    const [notes,setNotes] = useState([]);
    const [subscriptions,setSubscriptions] = useState(false);


    const getUserSubscriptions = (courses) => {
        console.log('localStorage.getItem(\'userId\')',localStorage.getItem('userId'))
        if(localStorage.getItem('userId') !== "null" ) {
            request(`/subscription/get_user_subscription?user_id=${localStorage.getItem('userId')}`, dispatch, {}, false).then(res => {
                if (res) {
                    setSubscriptions(res);
                    setNotes(courses.map(item => ({
                        ...item,
                        is_selected: res.notes_subscribe.findIndex(ele => item.id === ele) !== -1
                    })))
                }
            })
        }
    };


    useEffect(() => {
       if(currentTag) getAllChapters();
    },[currentTag]);

    const getAllChapters = () => {
        request(`/notes_list/by_tag?name=${currentTag}`,dispatch).then(res => {
            setNotes(res);
            getUserSubscriptions(res);
            if(res && res.length === 0){
                setShowDataNotFound(true)
            }
            else{
                setShowDataNotFound(false)
            }
            console.log("res",res)
        })
    };

    return (
        <div className='videoWrapper'>
            {showDataNotFound && <h3 className='notFound'>No Data Found !!</h3>}
            {notes.map(video => {
                return <VideoCourseCard data={video} type='Notes'/>
            })}

        </div>
    );
};

export default NotesList;

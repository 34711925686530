import React, { useEffect, useState } from "react";

import styles from "../../../App.module.css";
import Header from "../../../webiste-components/header/Header";
import Courses from "../../../webiste-components/Courses/Courses";
import AboutUs from "../../../webiste-components/AboutUs/AboutUs";
import basu from "../../../assets/basu-sir.jpg";
import siddharth from "../../../assets/siddharth-sir.jpg";
import aruna from "../../../assets/aruna.jpeg";
import circleLogo from "../../../assets/circle_logo.png";
import Footer from "../../../webiste-components/footer/footer";
import Videos from "../../../webiste-components/Videos/Videos";
import logo from '../../../assets/acharyaias.png'
import cong_1 from '../../../assets/cong_1.jpeg'
import cong_2 from '../../../assets/cong_2.jpeg'
import cong_3 from '../../../assets/cong_3.jpeg'




let quotes = [
  //   {
  //     quote:
  //       '"Our aim is to make Civil Services exam preparation - smart, targeted and  effective "',
  //     author: "Basu Sir",
  //     role: "Director of ARUNA IAS ACADEMY",
  //     image: basu,
  //   },
  {
    quote: "",
    author: "Siddharth sir",
    role: "Guiding force of ACHARYA IAS",
    image: siddharth,
  },
  // {
  //     quote:
  //         '"Quality in content, clarity in concepts and effort in right direction- key ingredients of ARUNA IAS ACADEMY to equip our candidates to clear Civil Services exam with top ranks"',
  //     author:'ARUNA mam,',
  //     role:'Technical head of ARUNA IAS ACADEMY',
  //     image:aruna,

  // },
];

let aboutUsText =
  "ACHARYA IAS, Bengaluru- we provide IAS coaching in Kannada and English. Our motto is to" +
  " provide classes with highest quality for Civil services exam preparation with targeted approach.We have vision" +
  " to transcend beyond language barriers. ಕನ್ನಡದವರಿಗಾಗಿ ಕನ್ನಡದವರಿಂದ ಕನ್ನಡದಲ್ಲಿ ಗುಣಮಟ್ಟದ ಐಎಎಸ್ ಪರೀಕ್ಷಾ ತರಬೇತಿ.";

function Website(props) {
  const [highlight, setHighlight] = useState("");

  useEffect(() => {
    console.log("highlight", highlight);
  });

  return (
    <div className={styles.App}>
      <Header
        onHeaderClick={(item) => {
          setHighlight(item);
          setTimeout(() => {
            setHighlight("");
          }, 5000);
        }}
      />
      {/*<Introduction/>*/}
      <div className={styles.introduction}>
        <p className={styles.supportText}>Sharpen your IAS Preparation with</p>
        <p className={styles.title}>ACHARYA IAS</p>
        <p className={styles.contactText}>Call us @+91-7996120669</p>


        {/*<marquee className={styles.inaug} direction={'left'} scrollamount={10}><mark style={{padding :20}}>Inauguration on 6th april 2022</mark></marquee>*/}
        {/*<marquee className={styles.inaug2} direction={'right'} scrollamount={10}>Inauguration on 6th april 2022</marquee>*/}

        {/*<p className={styles.inaug}>Inauguration on 6th april 2022</p>*/}
        {/*<AnimateText/>*/}
      </div>

        <div className={styles.congratulations}>
            <img style={{width:400,height:300}} src={cong_1}></img>
            <img style={{width:400,height:300}} src={cong_2}></img>
            <img style={{width:400,height:300}} src={cong_3}></img>
        </div>
      <h1 id={"Courses"} className={styles.heading}>
        Courses
      </h1>
      <Courses />
      <div style={{ height: 60 }} id={"aboutUs"}></div>
      <h1
        style={{ background: highlight === "aboutUs" ? "yellow" : "" }}
        onClick={() => setHighlight("aboutUs")}
        className={styles.heading}
      >
        About Us
      </h1>
      <AboutUs quote={aboutUsText} image={logo} />
      {/*<div className={styles.info}>*/}
      {/*    <p className={styles.aboutUsText}></p>*/}
      {/*</div>*/}

      <h1 className={styles.heading}>Tutors</h1>
      {/*<div>*/}
      {/*    {quotes.map(item => {*/}
      {/*        return <Quotes    {...item}/>*/}
      {/*    })}*/}
      {/*</div>*/}

      <h1
        id={"video"}
        style={{ background: highlight === "video" ? "yellow" : "" }}
        className={styles.heading}
      >
        Videos
      </h1>
      <Videos />
      <Footer
        onHeaderClick={(item) => {
          setHighlight(item);
          setTimeout(() => {
            setHighlight("");
          }, 5000);
        }}
        highlight={highlight}
      />
      <div className={styles.copyRight} style={{ marginBottom: 10 }}>
        © ACHARYA IAS BANGALORE. All Rights Reserved
      </div>
    </div>
  );
}

export default Website;

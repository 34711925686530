import React, {useEffect, useState} from 'react';
import styles from './Header.module.css'
import logo from '../../assets/acharyaias.png'
import {defaultAuth} from "../../config/firebaseConfig";
import * as axios from "axios";
import * as firebase from 'firebase'
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router-dom";
import request from "../../utils/apiRequest";
import {useDispatch} from "react-redux";
import { Menu } from 'antd';
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const items = [
    {
        label: 'CLASSES',
        key: 'courses',

    },
    {
        label: 'NOTES',
        key: 'notes',

    },
    {
        label: 'MAINS YOGA',
        key: 'mains_yoga',
        children: [
            {
                label: 'Question Bank',
                key: 'main_q_bank',
            },
            {
                label: 'Synopsis',
                key: 'mains_synopsis',
            },
        ],
    },
    {
        label: 'RESOURCES',
        key: 'resources',
        children: [
            {
                label: 'Notes',
                key: 'resource_notes',
            },
            {
                label: 'Scholarship Test',
                key: 'scholarship_test',

            },
        ],
    },

];


const Header = (props) => {
    const googleAuth = new firebase.auth.GoogleAuthProvider();
    let [user,setUser] = useState(null);
    let [menuClick,setMenuClick] = useState(false);
    const [current, setCurrent] = useState('home');
    const [navItems, setNavItems] = useState([]);
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
        switch (e.key) {
            case 'notes':{
                navigate('/notes')
                break
            }
            case 'courses':{
                navigate('/courses');
                break
            }
            case 'main_q_bank':{
                navigate('/resources/question_bank');
                break
            }
            case 'mains_synopsis':{
                navigate('/resources/synopsis');
                break
            }
            case 'login':{
                onSignIn();
                break
            }
            case'resource_notes':{
                navigate('/resources/res_notes');
                break
            }
            case'scholarship_test':{
                navigate('/resources/scholarship_test');
                break
            }
            case 'logout':{
                defaultAuth.signOut()
                break
            }
            case 'admin':{
                navigate('/admin');
                break
            }
            default :{
                navigate('/home')
                break
            }
        }
    };

    const getKey = () => {
        let arr = window.location.href.split('/')
        let url = arr[arr.length -1];
        let curr = 'home';
        switch (url) {
            case 'notes': {
                curr = 'notes';
                break;
            }
            case 'courses':{
                curr = 'courses';
                break
            }
            case 'login':{
                curr = 'login';
                break
            }
            case 'logout':{
                curr = 'account';
                break
            }
            case 'question_bank':{
                curr = 'resources';
                break
            }
            case 'synopsis':{
                curr = 'resources';
                break
            }
            case 'res_notes':{
                curr = 'resources';
                break
            }
            case 'admin':{
                curr = 'admin';
                break
            }
            default :{
                curr = 'home';
                break
            }
        }
        setCurrent(curr)
    };

    const checkUserStatus = () => {
        firebase.auth().onAuthStateChanged(function(firebaseUser) {
            if (firebaseUser != null) {
                setUser(firebaseUser)
                if(firebaseUser && !user?.id){
                    getUserData(firebaseUser);
                }
                localStorage.setItem('userId',firebaseUser.uid);
                localStorage.setItem('userEmail',firebaseUser.email)
            } else {
                setUser(null);
                localStorage.setItem('userId',null)
            }
        });
    };

    function getUserData (firebaseUser){
        console.log("user uid",firebaseUser.uid);
        if(firebaseUser && firebaseUser?.uid) {
            request(`/users?user_id=${firebaseUser.uid}`,dispatch, {}).then(res => {
                setUser(res);
                localStorage.setItem('user_name',res.name);
                console.log("user data",res)
            })
        }
    }

    useEffect(()=> {
        checkUserStatus();
        let firebaseUser = firebase.auth().currentUser;
        if(firebaseUser) setUser(firebaseUser);
        console.log("user hh",firebaseUser?.uid)

    },[]);

    useEffect(()=> {
        let navItemsCopy = [...items];
        if(user?.uid || user?.id){
            let children = [{type: 'group',label: user.displayName || user.name , key: 'name'}];
            if(user?.is_admin){
                children.push({
                    label: "CMS",
                    key: 'admin',
                })
            }
            children.push({label:'Logout',key:'logout'})
            navItemsCopy.push({
                label: 'ACCOUNT',
                key: 'account',
                children:children
            })
        }
        else{
            navItemsCopy.push({
                label: 'LOGIN',
                key: 'login',
            })
        }
        setNavItems([...navItemsCopy])
    },[user]);

    useEffect(()=> {
        getKey();
    });

    const onSignIn  = () => {
        defaultAuth.signInWithPopup(googleAuth).then((user)=> {
            let token = '';
            defaultAuth.currentUser.getIdToken().then(data =>{
                localStorage.setItem('access_token',data);
                axios.defaults.headers.common['x-auth-token'] = `${data}`;
                token = data;
                console.log(data);
                alert('Logged in');
                request(`/users/create`,dispatch,{method:'post',body:{}}).then(res=>
                {
                    setUser(res.data);
                    console.log(res.data)
                })
            });
        });
    };

    return (
        <div style={{width:'100%'}}>
            <div className={styles.contact_us}>
                <div className={styles.phoneNumber}>
                   Call us @ +91-7996120669
                </div>
                <div>
                        <ul style={{marginTop:10}}>
                            <a href={"https://api.whatsapp.com/send?phone=+917996120669"}>
                                <WhatsAppIcon className={styles.media} />
                            </a>
                            <a href={"https://t.me/Aruna_IAS_Academy"}>
                                <TelegramIcon className={styles.media} />
                            </a>
                            <a
                                href={
                                    "https://www.youtube.com/channel/UCuU0Ow8ZLiySpcclS-9x2Hw/videos"
                                }
                            >
                                <YouTubeIcon className={styles.media} />
                            </a>
                        </ul>
                    </div>
            </div>
            <div className={styles.wrapper}>
            {console.log('User yyy',user)}
            <img src={logo} onClick={()=>{
                navigate('/');
                window.location.reload()
            }} alt="logo" className={styles.logo}/>
            <div className={styles.headerDesktop}>
                <Menu onClick={onClick} style={{width: 540,border:'none',fontWeight:'bold',fontsize:'40px'}} selectedKeys={[current]} mode="horizontal" items={navItems} />
            </div>
            <div className={styles.menuIcon} ><MenuIcon onClick={()=> setMenuClick(!menuClick)} /></div>
            {menuClick && <div className={styles.rightMenu}><Menu onClick={onClick} selectedKeys={[current]} style={{width: 156,zIndex:99999999}} mode="vertical" items={navItems} /></div>}
        </div>
        </div>
    );
};

export default Header;

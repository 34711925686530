export let youtubeVideos = [
    {
        name:"",
        url:'https://www.youtube.com/embed/IK5QlIXorVQ'
    },
    {
        name:"",
        url:'https://www.youtube.com/embed/Z7osLEmEi1s'
    },
    {
        name:"",
        url:'https://www.youtube.com/embed/wVy9DFYu30k'
    },
    {
        name:"",
        url:'https://www.youtube.com/embed/b-ZsKnLZAgo'
    },

];


import React from 'react';
import {BallTriangle, MutatingDots, Rings} from "react-loader-spinner";
import './Loader.css'

const AppLoader = () => {
    return (
        <div className='loaderWrapper'>
            <BallTriangle color='var(--primary)' height={180} width={180} />
        </div>
    );
};

export default AppLoader;
import styles from "./App.module.css";
import React, { useEffect } from "react";
import MainCms from "./pages/cms/main_cms/MainCms";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Website from "./pages/website/pages/website";
import Login from "./pages/cms/login/Login";
import VideoCourses from "./pages/user/VideoCourses/VideoCourses";
import VideoLibrary from "./pages/user/VideoCourses/VideoLibrary/VideoLibrary";
import Notes from "./pages/user/Notes/Notes";
import TestSeries from "./pages/user/TestSeries/TestSeries";
import { Toaster } from "react-hot-toast";
import TestQuestionList from "./pages/user/TestSeries/TestQuestionList/TestQuestionList";
import Dropdown from "./pages/user/components/DropDown/Dropdown";

import { useSelector } from "react-redux";
import Loader from "./components/Loader/Loader";
import ResourceNotes from "./pages/user/ResourceNotes/ResourceNotes";
import ResourcesUser from "./pages/user/ResourceNotes/Resources/ResourcesUser";
import ResourceYogaQuestionBank from "./pages/cms/ResourceCMS/ResourceYoga/ResourceYogaQuestionBank/ResourceYogaQuestionBank";
import MainsYogaUserQBank from "./pages/user/ResourceNotes/ResourceYoga/ResourceMainsYogaQBank/MainsYogaUserQBank";
import ResourceYogaUser from "./pages/user/ResourceNotes/ResourceYoga/ResourceYogaUser";
import "./global.scss";
import Register from "./pages/user/ScholarshipTestsUser/Register";

function App() {
  useEffect(() => {
    // document.addEventListener("contextmenu", (event) => event.preventDefault());
  }, []);

  const counter = useSelector((state) => state);

  return (
    <div className={styles.App}>
      {counter.num > 0 && <Loader />}
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Website />} />
          <Route path="/home" exact element={<Website />} />
          <Route path="/admin/*" element={<MainCms />} />
          <Route path="/courses" element={<VideoCourses />} />
          <Route
            path="/resources/question_bank"
            element={<MainsYogaUserQBank />}
          />
          <Route path="/resources/synopsis" element={<ResourceYogaUser />} />
          <Route path="/resources/res_notes" element={<ResourceNotes />} />
          <Route path="/resources/scholarship_test" element={<Register />} />
          <Route
            path="/video_course"
            element={<VideoLibrary type={"Video"} />}
          />
          <Route path="/chapters" element={<VideoLibrary type={"Notes"} />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/testSeries" element={<TestSeries />} />
          <Route path="/test" element={<TestQuestionList />} />
          <Route path="/login/*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

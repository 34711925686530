import React from 'react';
import styles from './AbouUs.module.css'


function Quotes(props) {
    return (
        <div className={styles.quotesWrapper}>
            <div>
                <img src={props.image} className={styles.tutorPicNew}/>
            </div>
            <div>
                <h2 className={styles.quote}>
                    {props.quote}
                </h2>
                <h2 className={styles.author}>
                    {props.author}
                </h2>
                <h2 className={styles.role}>
                    {props.role}
                </h2>

                </div>

        </div>)
}

export default Quotes;

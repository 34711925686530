import React, {useEffect, useState} from 'react';
import './Dropdown.css'
import {Checkbox} from "@mui/material";
import useComponentVisible from "../../../../utils/useComponentVisible";

const Dropdown = ({options,selectAll,handleChange,type,defaultSelected,showSearch}) => {

    const [isOpen,setIsOpen] = useState(false)
    const [filterOptions,setFilterOptions] = useState(false)
    const { ref, isComponentVisible } = useComponentVisible(true);

    const [searchText,setSearchText] = useState('')


    useEffect(()=> {
        console.log('searchText',searchText);
        if(searchText){
            setFilterOptions(options.filter(item => item.label.includes(searchText)))
        }
        else{
            setFilterOptions(options)
        }

    },[options,searchText]);



    function expand() {
        setIsOpen(true);
    }

    function close() {
        setIsOpen(false);
    }


    return (
        <div className='dropdownWrapper'>
            <button className='dropdownButton' onClick={()=>{setIsOpen(!isOpen)}}>{defaultSelected || 'Select options'}</button>

            {(isOpen && isComponentVisible) && <div className='dropdownOptions' onFocus={expand} onBlur={close} >
                {showSearch &&<input value={searchText} placeholder={'search'} onBlur={(e)=> {
                    e.stopPropagation()
                }} style={{width:280}} onChange={(e)=> setSearchText(e.target.value)}/>}
                {filterOptions.map((option,index) => {
                        return <div className='optionWrapper'>
                            {selectAll &&
                            <Checkbox checked={option.is_selected} onChange={() => handleChange(option, type)}/>}
                            <p className='labelText' title={option.label} onClick={() => {
                                if (!selectAll) setIsOpen(false);
                                handleChange(option, type)
                            }}>{option.label}</p>
                        </div>
                })}
            </div>}
        </div>
    );
};

export default Dropdown;
import React, {useEffect, useState} from 'react';
import './VideoLibrary.css'
import axios from "axios";
import VideoLibraryCard from "../../components/VideoLibraryCard/VideoLibraryCard";
import Header from "../../../../webiste-components/header/Header";
import {base_url} from "../../../../utils";
import { useDispatch } from "react-redux";
import request from "../../../../utils/apiRequest";


const VideoLibrary = ({ route, navigation }) => {

    const [type,setType] = useState(localStorage.getItem('type'))
    const [id,setId] = useState(localStorage.getItem('id'))
    const [videoList,setVideoList] = useState([]);
    const dispatch = useDispatch();
    const [showVideoNotFound,setShowVideoNotFound] = useState(false);


    useEffect(()=> {
        getVideoList()
    },[]);

    function getVideoList (){
        let url;
        if(type === 'Notes'){
            url = `/notes/all?notes_list_id=${id}`
        }
        else if(type === 'TestSeries'){
            url = `/test_series/all?series_list_id=${id}`
        }
        else{
            url = `/chapters/all?course_id=${id}`
        }

        request(url,dispatch).then(res => {
            let list = [];
            // res.sort((a, b) => a.description.toString().toLowerCase().localeCompare(b.description.toString().toLowerCase()));
            res.sort((a, b) => a.description.split('VID-')[1]?.toString().toLowerCase()?.localeCompare(b?.description?.split('VID-')[1]?.toString()?.toLowerCase()));
            console.log('sort',res);
            setVideoList(res);
            if(!res?.length){
                setShowVideoNotFound(true)
            }
            console.log("res",res)
        })
    }


    return (
        <div className='videoLibraryWrapper'>
            <Header/>
            <h2 style={{padding:20,marginLeft:30}}>{type}</h2>
            {showVideoNotFound && <h2 className='notFound'>Data not found</h2>}
            <div className='videoCardsLayout'>
                {videoList?.map(video => {
                    return <VideoLibraryCard type={type} data={video}/>
                })}
            </div>
        </div>
    );
};

export default VideoLibrary;
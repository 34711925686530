import React from 'react';
import { Player } from 'video-react';


const CurrentAffairs = () => {

    let videoLink = "https://aruna-ias-academy-courses.s3.us-east-1.amazonaws.com/sample_3840x2160.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHMaCmFwLXNvdXRoLTEiSDBGAiEAoNQiZXDcbY6a7FXCGkDGpnlxOE563IuCivMG8uOjHqgCIQDQ2PWzqiLBTsZZe0gyxkIaThrqj%2FdFjXHGpt6Fym%2BwSCrkAghMEAAaDDQyODgwNDA3NzcxMyIMxkglUMQnHkSwFSqrKsEC1L752PoCaaURstQ8U91pOgSVcvonDKC0%2B5p1JS8rrgnALwEdfxt94PL9VILkhMSuEQBr9GhTkaWqvzmd%2Bgk6B7a0ZnWv3fjOKCiYFOcpXNLmpcHyAaahQf0Lhd6ekHMc1P2%2Fg6HYiHiC6RdkHSg0zESW1gRt46a5CrB%2Bko81sh62wV3HGoIUu1bzpjxJhR4gNI7hJdnpMgjI1tOPO1HCD5mnyFaGOtMkyRi2bmbHMjSf5YosUUgGNVcVnKcPwom5RaYGGUe%2F5YRevj%2B7GYWQY7hmD0Tx6PEBTFHV%2FImNjSPoaetfZDs6azfhU2w%2FeAIs3GddimH%2Bs%2FfBrNQZQR2ylfXRdGE5oJfbUKoWHMc%2FM7rz9rZeYxKb2e%2BCwPmgjMZ6ku0fmRUBXJME7f%2FwkElcTaH6ej%2BOSY%2BDPUdxaVGVaUxGMLy89ZMGOrICD2A%2F6YOh%2B9g3R6e7wkABUrpsnQb9gnBk01pgvLS1BxQelTp2%2F%2FlPtId%2FlfRRsSHGnRu%2FPagq3i7DKF4F86t5E59PXBt%2BPyC4jfdhDiMAI2yR5cDeINS0xjvqJfq2JFq70XtknsW3bOjcaQemVf%2FeK1Rh5X8NEmH4vz4BAiRnQ0QhvMfTQAi9oXTT2XqRNiEKCxeld8uUz%2BSDc5J4Tyukn7tmBij68%2F6qALLwyCpIrPII0r%2F1wqgw82LtXRjDfKNSxaQhfXs7ZYlkRFEo4c%2Fxd32pK5uQR3Alk6uc0Fa5SQeXtJkCu5K%2FoX5QXICB%2BctP%2FxbdrhSidZVK8M9ZMYMf3kAIKqZW4uoE0tzGBIIu1wExOPRxiTC5%2BZ876SBRjZs1Q3xFH1UyOwu3bvd0%2Fl%2BD0eaS&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220512T194603Z&X-Amz-SignedHeaders=host&X-Amz-Expires=600&X-Amz-Credential=ASIAWHVVW7CI74VOENPH%2F20220512%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=4818bf93434e18e67be7eb814e9c1b489a95a2128b67b91f81458bf73b1df2ed"

    return (
        <div>
            <Player
                    playsInline
                    poster="/assets/poster.png"
                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                    fluid={false}
                    width={480}
                    height={272}>
            </Player>
        </div>
    );
};

export default CurrentAffairs;
import React from "react";
import styles from "./footer.module.css";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

function Footer(props) {
  return (
    <div className={styles.footer} id={"contact"}>
      <div className={styles.followUs}>
        <a
          className={styles.links}
          href={"/#aboutUs"}
          onClick={() => props.onHeaderClick("aboutUs")}
        >
          About Us
        </a>
        <a
          className={styles.links}
          onClick={() => props.onHeaderClick("courses")}
          href={"/#Courses"}
        >
          Courses
        </a>
        <a
          className={styles.links}
          onClick={() => props.onHeaderClick("video")}
          href={"/#video"}
        >
          Videos
        </a>
      </div>
      <div className={styles.linksWrapper}>
        <label className={styles.heading}>Follow Us</label>
        <ul className={styles.socialMedia}>
          <a href={"https://api.whatsapp.com/send?phone=+917996120669"}>
            <WhatsAppIcon className={styles.media} />
          </a>
          <a href={"https://t.me/Aruna_IAS_Academy"}>
            <TelegramIcon className={styles.media} />
          </a>
          <a
            href={
              "https://www.youtube.com/channel/UCuU0Ow8ZLiySpcclS-9x2Hw/videos"
            }
          >
            <YouTubeIcon className={styles.media} />
          </a>
        </ul>
        <div className={styles.contact}>
          <a className={styles.phoneNumber} href="tel:+91-7996120669">
            {" "}
            <LocalPhoneIcon className={styles.media} /> +91-7996120669
          </a>{" "}
          <br></br>
          <p className={styles.mail}>
            {" "}
            <a
              className={styles.mailFooter}
              href={"mailto:acharyaiasbengaluru@gmail.com"}
            >
              <EmailIcon className={styles.media} />
              acharyaiasbengaluru@gmail.com
            </a>
          </p>
        </div>
      </div>
      <div className={styles.address}>
        <h3
          className={styles.heading}
          style={{
            background: props.highlight === "contact" ? "yellow" : "black",
            color: props.highlight === "contact" ? "black" : "white",
            borderBottomColor: props.highlight === "contact" ? "red" : "white",
          }}
        >
          Address{" "}
        </h3>
        <p>ACHARYA IAS</p>
        <p>#80, 1st floor, 20th main</p>
        <p>Marenhalli main road,</p>
        <p>Above Namasthe hotel,Vijaynagar</p>
        <p>Ph:+91-7996120669</p>
      </div>
      <iframe
        className={styles.map}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0396358840426!2d77.53264155037003!3d12.969315590812522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d46c027ea5b%3A0x5938de77b856f5fa!2sARUNA%20IAS%20Academy!5e0!3m2!1sen!2sin!4v1648800009964!5m2!1sen!2sin"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}

export default Footer;

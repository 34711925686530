import React from 'react';

const ChapterCard = (props) => {

    console.log('props ChapterCard',props)

    return (
        <div className="testSeriesList" onClick={() => props.onTestSeriesClick(props.data)}>
            <p className='tName'>{props.data.name || props.data.notes_name}</p>
            <p className='tDescription'>{props.data.description}</p>
            <p className='tPrice'>{props.data.reading_time} min read</p>
            <p className='tTag'>{props.data.tags}</p>
            <button className="button" onClick={(e) => {
                e.stopPropagation();
                props.onUpdateClick(props.data)
            }}>UPDATE
            </button>
            <button className="button" onClick={(e) => {
                e.stopPropagation();
                props.onDelete(props.data)
            }}>DELETE
            </button>
        </div>
    );
};

export default ChapterCard;

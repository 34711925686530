import React, {useEffect} from 'react';
import {Player} from "video-react";
import './VideoLibraryCard.css'
import axios from "axios";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import '../../../../video-player.css'
import SettingsIcon from '@mui/icons-material/Settings';
import Dropdown from "../DropDown/Dropdown";
import ReactPlayer from 'react-player'
import FullscreenIcon from '@mui/icons-material/Fullscreen';



const VideoLibraryCard = ({data,type}) => {

    const navigate = useNavigate();

    useEffect(()=> {
        console.log("Data",data,type)
        console.log("type",type)
    },[]);

    const downDoc = (docId,url,name) => {
        toast('Download initiated...',{duration:4000,position: 'bottom-right',})
        const res = axios({url: url, method: 'GET', responseType: 'blob',})
            .then((response) => {
                console.log("download completed")
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.pdf`);
                document.body.appendChild(link);
                link.click();
                toast('Download completed!',{duration:4000,position: 'bottom-right',})
            });
    };

    const onHandleChange = (value) => {
        console.log('onHandleChange',value)
    };

    const getTestCard  = (data) => {
        return <div className="mainWrapper">
            <h2 className='chapterName'>{data.name}</h2>
            <p className='chapterDescription'>{data.description}</p>
            {/*<div className='divider'></div>*/}
            <div className="section">
                <p className='sectionTitle'>Questions</p>
                <p> {data?.question_count || "--"}</p>
            </div>
            <div className="section">
                <p className='sectionTitle'>max Marks</p>
                <p>{data?.question_count * 2  || "--"} </p>
            </div>
            <div className="section">
                <p className='sectionTitle'>Time</p>
                <p>{data.time || '120 Min'} </p>
            </div>
            <button className={`downloadPdfButton ${(!data?.question_count || (localStorage.getItem('userId') === 'null')) ? 'disableAttempt' : ''}` } onClick={()=> {
                if(data?.question_count) {
                    localStorage.setItem('test_series_id', data.id)
                    localStorage.setItem('test_series_name', data.name)
                    localStorage.setItem('test_series_time', data.time)
                    navigate('/test')
                }
            }}>Attempt-></button>
        </div>
    };

    function toggleFullscreen(id) {
        if (!document.fullscreenElement) {
            document.getElementById(id).requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }

    return (<>
            {type !=="TestSeries"  ?
                <div className='videoLibraryCardWrapper'>
                    {type !=="Notes" ?
                        <div id={`vid-cont-${data.signed_url}`} className="video-container">
                            <video className="video" controls controlsList="nodownload" poster={data?.thumbnail_url} >
                                <source src={data.signed_url} type='video/mp4' id='mp4'/>
                            </video>

                            <i className="watermark">{localStorage.getItem('userEmail')}</i>
                            <button className="fullscreen-button" onClick={()=>toggleFullscreen(`vid-cont-${data.signed_url}`)}><FullscreenIcon/>
                            </button>
                        </div> : null}


                    <div className='videoDesLayout'>
                        <p className='chapterName'>{data?.name}</p>
                        <p className='chapterDescription'>{data.description || 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'}</p>
                        <p className='chapterReadTIme'>{data.reading_time} min {type === "Notes" ? 'read' : 'On Demand Video'}</p>
                        {(type !=="Notes" &&  !data.signed_url) && <button onClick={()=> toast('Please contact +91-7996120669 to subscribe',{duration:4000,position: 'bottom-right',})} className='subscribe'>Subscribe To Watch</button> }
                    </div>
                    {type === "Notes" && <button className='downloadPdfButton' onClick={()=> downDoc('doc',data.signed_url,data.notes_name)}>Download pdf</button>}
                </div> : getTestCard(data)}
        </>
    );
};


export default VideoLibraryCard;


{/*<div className='videoWrapper' id='vid-cont'>*/}
{/*    <i className="watermark">Moud's Vid</i>*/}
{/*    <video className="video" controls>*/}
{/*        <source src={data.signed_url} type='video/mp4' id='mp4'/>*/}
{/*    </video>*/}
{/*    /!*<div className='video'><ReactPlayer*!/*/}
{/*    /!*config={{ file: { attributes: { controlsList: 'nodownload' } } }}*!/*/}
{/*    /!*light={data.thumbnail_url}*!/*/}
{/*    /!*width={380} height={272} controls url={data.signed_url} />*!/*/}
{/*    <button className="fullscreen-button" onClick={toggleFullscreen}>Toggle fullscreen*/}
{/*    </button>*/}
{/*    /!*</div>*!/*/}
{/*</div>*/}

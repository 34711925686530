import React from 'react';
import './VideoCourseCard.css'
import { useNavigate } from 'react-router-dom'
import ArticleIcon from '@mui/icons-material/Article';
import {toast} from "react-hot-toast";


const VideoCourseCard = ({data,courseId,type}) => {
    const navigate = useNavigate();
    return (
        <div className='vCardWrapper'>
            <p className='vCTitle'>{data?.name}</p>
            <p className='vCount'>{type === 'Notes' ? `${data.notes_count} Chapters` : type === 'TestSeries' ? `${data.test_series_count} Tests` : `${data.chapter_count} Chapters`}</p>
            {/*<p className='vCPrice'>₹ {type === 'TestSeries' ? data.price : data.amount}</p>*/}
            <p className='vCDescription'>{data.description}</p>
            <div className='vLibraryLayout' onClick={()=> {
                localStorage.setItem('id',data.id);
                localStorage.setItem('type',type);
                if(type !== 'Notes' ) {
                    navigate('/video_course')
                }
                else if(type === 'TestSeries') {
                    navigate('/tests')
                }
                else {
                    toast('Please contact +91-7996120669 to subscribe',{duration:4000,position: 'bottom-right',})
                }
            }}>
                 <ArticleIcon/>
                <p style={{marginBottom:0}}>View Library</p>
            </div>
            <button onClick={()=> {
                if(!data.is_selected) toast('Please contact +91-7996120669 to subscribe',{duration:4000,position: 'bottom-right',})

            }} className='vSubscribe'>{data.is_selected ? 'Subscribed' : "Subscribe"}</button>
        </div>
    );
};

export default VideoCourseCard;

import React, {useEffect, useState} from 'react';
import * as firebase from 'firebase'
import './ResourceYogaUser.css'
import {toast} from "react-hot-toast";
import axios from "axios";
import Header from "../../../../webiste-components/header/Header";


const ResourceNotes = () => {

    const [notesResources,setNotesResources] = useState([]);

    useEffect(()=> {
        getAllResources();
    },[]);


    const getAllResources = () => {
        firebase.firestore().collection("resource-yoga-synopsis").get().then((querySnapshot) => {
            let notes = [];
            querySnapshot.forEach((doc) => {
                console.log("dsd",doc.id);
                let note = doc.data();
                note.id = doc.id;
                notes.push(note);
            });
            console.log("getAllResources",notes)
            setNotesResources(notes)
        });
    };


    const downDoc = (url,name) => {
        toast('Download initiated...',{duration:4000,position: 'bottom-right',})
        const res = axios({url: url, method: 'GET', responseType: 'blob',})
            .then((response) => {
                console.log("download completed");
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.pdf`);
                document.body.appendChild(link);
                link.click();
                toast('Download completed!',{duration:4000,position: 'bottom-right',})
            });
    };

    return (
        <div className='yogaWrapper'>
            <Header/>
            <h2 style={{padding:20}}>Mains Yoga Synopsis</h2>
            <div className='notesWrapper'>
                {notesResources.map(data => {
                    return <div className='yogaCardWrapper'>
                        <div className='videoDesLayout'>
                            <p className='chapterName'>{data?.title}</p>
                            <p className='chapterDescription'>{data.description || 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'}</p>
                            <p className='chapterReadTIme'>{data.readingTime} </p>
                            {/*{data.url && <button onClick={()=> downDoc(data.url,data?.title)}>Download</button>}*/}
                            <p className='chapterReadTIme'>Synopsis </p>
                            {data?.video_url && <iframe width="335" height="300" src={data?.video_url}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>}
                            {data.url && <a className='download' href={data.url} download={data.title} target='_blank'>Open file</a>}
                        </div>
                    </div>})}
            </div>
        </div>
    );
};

export default ResourceNotes;



import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({handleClose,onSave,title,message}) {

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure want to do this?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please make sure you are making correct changes.. Users will be able see the details once you click on YES here
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{backgroundColor:'var(--primary)',color:'white'}} onClick={handleClose}>NO</Button>
                    <Button style={{backgroundColor:'var(--primary)',color:'white'}} onClick={onSave} autoFocus>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
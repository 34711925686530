import React from 'react';
import './VideoCourseCard.css'

const VideoCourseCard = (props) => {
    return (
        <div className="videoCourseCard" onClick={()=>props.onCourseClick(props.data)}>
            <p className='vName' title={props?.data?.name}>{props?.data?.name}</p>
            <p className='vDescription' title={props?.data?.description}>Description: {props?.data?.description || "--"}</p>
            <p className='vPrice'>Price : ₹ {props?.data?.amount}</p>
            <p className='vTag'>Tag : {props?.data?.tags}</p>
            <div className={'footerBtnLayout'}><button className="button" onClick={(e)=> {e.stopPropagation();props.onUpdateClick(props.data)}}>UPDATE</button>
            <button className="button" onClick={(e)=> {e.stopPropagation();props.onDelete(props.data)}}>DELETE</button>
            </div>
            </div>
    );
};

export default VideoCourseCard;

import React, {useEffect, useState} from 'react';
import TagList from "../components/TagList/TagList";
import VideoCourseList from "./VideoCourseList/VideoCourseList";
import './VideoCourses.css'
import Header from "../../../webiste-components/header/Header";
import {useDispatch} from "react-redux";
import request from "../../../utils/apiRequest";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from "@mui/material/Button";


const VideoCourses = () => {

    const [videoCourseTags,setVideoCourseTags] = useState([])
    const [currentTag,setCurrentTag] = useState('')
    const dispatch = useDispatch();
    const [isSmallScreen,setIsSmallScreen] = useState(false);
    const [isCourseListOpen,setIsCourseListOpen] = useState(false);

    const updateScreenWidth = () => {
        console.log('isSmallScreen1',window.innerWidth)
        if(window.innerWidth < 700){
            setIsSmallScreen(true)
        }
        else{
            setIsSmallScreen(false)
        }
    };


    useEffect(() => {
        getVideoCourseTags();
        window.addEventListener('resize',updateScreenWidth);
        updateScreenWidth()
        return () => {
            window.removeEventListener('resize',updateScreenWidth)
        }
    },[]);


    function getVideoCourseTags (){
        request(`/tags?key=CourseList`,dispatch).then(res => {
            let tags = res.map(item => item.name)
            setVideoCourseTags(tags);
            console.log("isSmallScreen",isSmallScreen);
            // if(!isSmallScreen){
            //     setCurrentTag(tags[0]);
            // }
            // else{
            //     setCurrentTag('');
            // }
            console.log("getAllTags",tags)
        })
    }

    return (
        <div className='wrapper'>
            <Header/>
            <h2 style={{padding:20,marginLeft:30}}>Classes</h2>
            {!isSmallScreen ? <div className='videoCourseWrapper'>
                    <div className='tagsView'>
                        <TagList setCurrentTag={setCurrentTag} currentTag={currentTag} tags={videoCourseTags}/>
                    </div>
                    <div className='rightPanel'>
                        <VideoCourseList activeTag={currentTag}/>
                    </div>
                </div> :
                <div className='videoCourseSmallScreenWrapper'>
                    {!currentTag ? <div className='tagsView'>
                            <TagList setCurrentTag={setCurrentTag} currentTag={currentTag} tags={videoCourseTags}/>
                        </div> :
                        <div className='rightPanelSmall'>
                            <Button variant="outlined" className='navBack' onClick={()=>{
                                setCurrentTag('')
                            }} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                            <VideoCourseList activeTag={currentTag}/>
                        </div>
                    }

                </div>
            }
        </div>
    );
};

export default VideoCourses;

import React from 'react';
import CourseCard from "./CourseCard";
import styles from './Courses.module.css'


let courses = [
    // {
    //     title:'ಕನ್ನಡದಲ್ಲಿ ಐಎಎಸ್  ಫೌಂಡೇಶನ್ ಬ್ಯಾಚ್ - 2024',
    //     description1:'ತರಗತಿಯಲ್ಲಿ ನಿರ್ದಿಷ್ಟ ವಾರದಲ್ಲಿ ಒಳಗೊಂಡಿರುವ ಪಠ್ಯಕ್ರಮದ ಪ್ರಕಾರ ಸಾಪ್ತಾಹಿಕ ಪ್ರಿಲಿಮ್ಸ್ ಮತ್ತು ಮುಖ್ಯ ಪರೀಕ್ಷೆಯ ಸರಣಿ.'
    //     ,
    //     description2:'ಪ್ರತಿ ವಾರ ತಮ್ಮ ಜ್ಞಾನದ ಹಾರಿಜಾನ್ ಅನ್ನು ವಿಸ್ತರಿಸಲು ಮತ್ತು ಪ್ರಸ್ತುತ ಘಟನೆಗಳಿಗೆ ಸ್ಥಿರ ಜ್ಞಾನವನ್ನು ಲಿಂಕ್ ಮಾಡಲು ಮತ್ತು ಅನ್ವಯಿಸಲು.',
    //     price:null
    // },
    {
        title:'Foundation course for 2024 in English',
        description1:'Weekly Prelims ' +
            '& Mains Test Series as ' +
            'per syllabus covered in ' +
            'that particular Week in ' +
            'the Class.',
        description2:'Every week to ' +
            'broader their knowledge ' +
            'horizon and link and ' +
            'apply static knowledge to ' +
            'current events.' ,
        price:null
    },
    {
        title:'Toppers Batch (A Rank Assured)',
        description1:' Mentor ship program with Prelims and Mains Test\n' +
            'series both in Kannada and English.',
        description2:'A Dedicated and Complete Year has given for\n' +
            'Prelims and Mains Preparation',
        price:null
    },
    {
        title:'IAS ಪ್ರತಿಜ್ಞೆ',
        description1:'Targeted Prelims & Mains Mentorship Program\n' +
            '50 days before the actual UPSC Prelims and Mains\n' +
            'Examination',
        description2:' Principle based approach ',
        description3:'Skill enablement through intensive practice.',
        price:null
    },
    {
        title:'CSAT Crash Course',
        description1:'Discussion on Previous 15 year question papers with complete explanation.',
        description2:'Time management in exam.',
        description3:'Enough solved examples and a practice on every topic for easy understanding.',
        price:null
    },
];

function Courses(props) {
    return (
        <div className={styles.wrapper}>
            {courses.map((course ,index)=> {
                return <CourseCard fixHeight={true} titleStyle={index%2 === 0 ?styles.titleStyleRed : styles.titleStyleGreen} data = {course} />
            })}
        </div>
    );
}

export default Courses;

import React, {useEffect} from 'react';
import './SummaryDetails.css'
import SummaryQuestionDetails from "./SummaryQuestiondetails";
import html2canvas from "html2canvas";
// import ReactPdf from "react-to-pdf";
import jsPDF from 'jspdf';


const ref = React.createRef();


const SummaryDetails = ({data}) => {

    useEffect(()=>{
        console.log('SummaryDetails',data)
    },[]);


    const onDownload = () => {
        html2canvas(document.querySelector("#summaryLayout")).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("download.pdf");
        });
    };

    return (
        <div>
            <button onClick={onDownload}>Download</button>
            {/*<ReactPdf*/}
            {/*    targetRef={ref} filename="code-example.pdf">*/}
            {/*    {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}*/}
            {/*</ReactPdf>*/}
            <div id='summaryLayout' className='summaryLayout' ref={ref}>
                <h1 className='testReport'>Test Report</h1>
                <h2 className='username'>{localStorage.getItem('user_name')}</h2>
                <div className='overview'><p>Total number of Questions: {data?.total_questions}</p>
                    <p>Total questions answered: {data?.answered}</p>
                    <p>Total questions answered correctly: {data?.correct}</p>
                    <p>Percentage: {Math.floor(data.percentage)}%</p>
                </div>
                {data?.questions && data?.questions.map((item,index) => {
                    return <SummaryQuestionDetails data={item} qIndex={index + 1}/>
                })}
            </div>
        </div>

    );
};

export default SummaryDetails;
import React from 'react';
import styles from './Videos.module.css'
import {youtubeVideos} from "../../video_urls";


const Videos = () => {
    return (
        <div className={styles.wrapper}>
            {youtubeVideos.map(item =>{
                return <iframe width="400" height="400" src={item.url}
                               title="YouTube video player" frameBorder="0"
                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                               allowFullScreen/>
            })}
        </div>
    );
};

export default Videos;

import React, {useEffect, useState} from 'react';
import TagList from "../components/TagList/TagList";
import './TestSeries.css'
import axios from "axios";
import {base_url} from "../../../utils";
import Header from "../../../webiste-components/header/Header";
import VideoCourseList from "../Notes/NotesList/NotesList";
import TestSeriesList from "./TestSeriesList/TestSeriesList";
import request from "../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/material/SvgIcon/SvgIcon";

const TestSeries = () => {

    const dispatch = useDispatch();
    const [videoCourseTags,setVideoCourseTags] = useState([]);
    const [currentTag,setCurrentTag] = useState('');
    const [isSmallScreen,setIsSmallScreen] = useState(false);

    const updateScreenWidth = () => {
        if(window.innerWidth < 700){
            setIsSmallScreen(true)
        }
        else{
            setIsSmallScreen(false)
        }
    };


    useEffect(() => {
        getTestSeriesTags()
        window.addEventListener('resize',updateScreenWidth);
        updateScreenWidth();
        return () => {
            window.removeEventListener('resize',updateScreenWidth)
        }
    },[]);

    function getTestSeriesTags (){
        request(`/tags?key=TestSeriesList`,dispatch).then(res => {
            let tags = res.map(item => item.name)
            setVideoCourseTags(tags);
            setCurrentTag(tags[0]);
            console.log("getAllTags",tags)
        })
    }

    return (
        <div className='wrapper'>
            <Header/>
            <h2 style={{padding:20,marginLeft:30}}>Test Series</h2>
            {!isSmallScreen ? <div className='videoCourseWrapper'>
                <div className='tagsView'>
                    <TagList setCurrentTag={setCurrentTag} currentTag={currentTag} tags={videoCourseTags} />
                </div>
                <div className='rightPanel'>
                    <TestSeriesList currentTag={currentTag} />
                </div>
            </div> :  <div className='videoCourseSmallScreenWrapper'>
                    {!currentTag ? <div className='tagsView'>
                            <TagList setCurrentTag={setCurrentTag} currentTag={currentTag} tags={videoCourseTags} />
                        </div> :
                        <div className='rightPanelSmall'>
                            <Button variant="outlined" className='navBack' onClick={()=>{
                                setCurrentTag('')
                            }} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                            <TestSeriesList currentTag={currentTag} />
                        </div>
                    }

                </div>}

        </div>
    );
};

export default TestSeries;

import './Register.css'
import * as firebase from "firebase";
import {toast} from "react-hot-toast";
import React, {useState} from 'react';


const Register = () => {

    const [userDetails,setUserDetails] = useState({});

    const handleChange = (e) => {
        const {name,value} = e.target;
        let userDetailsCopy = userDetails;
        userDetailsCopy[name] = value;
        setUserDetails(userDetailsCopy);
    };

    const startTest  = () => {
        if(userDetails.email) {
            console.log("userDetails", userDetails);
            firebase.firestore().collection('user').add(userDetails).then((docRef) => {
                const d = new Date();
                let time = d.getTime();
                firebase.firestore().collection('scholarship_test_result').doc(docRef.id).set({startTime: time}).then(() => {
                    toast('Test started', {duration: 4000, position: 'bottom-right',})
                });
            });
        }
    };

    return (
        <div className='registerLayout'>
            <div className='innerLayout'>
                <div>
                    <label>Name</label>
                    <input name='name' value={userDetails.name} onChange={handleChange} type="text" />
                </div>
                <div>
                    <label>Email</label>
                    <input name='email' value={userDetails.email} onChange={handleChange} type="text" />
                </div>
                <div>
                    <label>Phone Number</label>
                    <input name='phoneNumber' value={userDetails.phoneNumber} onChange={handleChange} type="text" />
                </div>
                <div>
                    <label>Dob</label>
                    <input name='phoneNumber' value={userDetails.phoneNumber} onChange={handleChange} type="date" />
                </div>
                <div>
                    <button className='startButton' onClick={startTest}>Start Test</button>
                </div>
            </div>
        </div>
    );
};

export default Register;
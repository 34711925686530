import React, {useEffect, useState} from 'react';
import TagList from "../components/TagList/TagList";
import './Notes.css'
import axios from "axios";
import {base_url} from "../../../utils";
import Header from "../../../webiste-components/header/Header";
import NotesList from "./NotesList/NotesList";
import request from "../../../utils/apiRequest";
import {useDispatch} from "react-redux";
import VideoCourseList from "../VideoCourses/VideoCourseList/VideoCourseList";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/material/SvgIcon/SvgIcon";


const NotesCMS = () => {

    const dispatch = useDispatch()
    const [notesTags,setNotesTags] = useState([])
    const [currentTag,setCurrentTag] = useState('')
    const [isSmallScreen,setIsSmallScreen] = useState(false);

    const updateScreenWidth = () => {
        if(window.innerWidth < 700){
            setIsSmallScreen(true)
        }
        else{
            setIsSmallScreen(false)
        }
    };

    useEffect(()=>{
        getNotesTags()
        window.addEventListener('resize',updateScreenWidth);
        updateScreenWidth();
        return () => {
            window.removeEventListener('resize',updateScreenWidth)
        }
    },[]);

    function getNotesTags (){
        request(`/tags?key=NotesList`,dispatch).then(res => {
            let tags = res.map(item => item.name)
            setNotesTags(tags);
            setCurrentTag(tags[0]);
            console.log("getAllTags",tags)
        })
    }

    return (
        <div className='wrapper'>
            <Header/>
            <h2 style={{padding:20,marginLeft:30}}>Notes</h2>
            {!isSmallScreen ? <div className='videoCourseWrapper'>
                    <div className='tagsView'>
                        <TagList setCurrentTag={setCurrentTag} currentTag={currentTag} tags={notesTags} />
                    </div>
                    <div className='rightPanel'>
                        <NotesList currentTag={currentTag}/>
                    </div>
                </div> :
                <div className='videoCourseSmallScreenWrapper'>
                    {!currentTag ? <div className='tagsView'>
                            <TagList setCurrentTag={setCurrentTag} currentTag={currentTag} tags={notesTags}/>
                        </div> :
                        <div className='rightPanelSmall'>
                            <Button variant="outlined" className='navBack' onClick={()=>{
                                setCurrentTag('')
                            }} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                            <NotesList currentTag={currentTag}/>
                        </div>
                    }

                </div>
            }

        </div>
    );
};

export default NotesCMS;

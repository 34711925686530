import React from 'react';
import './QuestionCard.css'

const QuestionCard = ({data,qIndex,onUpdateClick,onDelete,readOnly,onOptionClick}) => {
    return (
        <div className='questionCardWrapper'>
            <div className='question'>{qIndex + 1}.  <div dangerouslySetInnerHTML={{ __html: data.question}} /></div>
            {
                data.options.map((item,index) => {
                    return <div className='optionLayout'>
                        <button className='optionBullet'>{index +1}. </button>
                        <p onClick={()=> onOptionClick(data.id,item)} className='optionAnswer'>{item}</p>
                    </div>
                })
            }
            <div className={'btnLayout'}>{!readOnly &&<button className="button" onClick={(e)=> {e.stopPropagation();onUpdateClick(data)}}>UPDATE</button>}
            {!readOnly &&<button className="button" onClick={(e)=> {e.stopPropagation();onDelete(data)}}>DELETE</button>}
            </div>

        </div>
    );
};

export default QuestionCard;

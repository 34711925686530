import React from 'react';
import './TestQuestionCard.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TestQuestionCard = ({data,qIndex,onOptionClick}) => {
    return (
        <div className='tQuestionCardWrapper'>
            <div dangerouslySetInnerHTML={{ __html: data?.question}} />
            {/*<p className='question'>{qIndex}. {data?.question}</p>*/}
            {
                data.options.map((item,index) => {
                    return <div className='optionLayout' onClick={()=> onOptionClick(data.id,item)} >
                        <button className='optionBullet'>{index +1}. </button>
                        <p className='optionAnswer'>{item}</p>
                        {data.answer === item  && <CheckCircleIcon style={{color:'green',marginLeft:'10px'}}/> }
                    </div>
                })
            }
            {/*<button className="button" onClick={(e)=> {e.stopPropagation();onUpdateClick(data)}}>Submit</button>*/}
        </div>
    );
};

export default TestQuestionCard;

import React, {useEffect, useState} from 'react';
import * as firebase from 'firebase'
import './ResourceYogaQuestionBank.css'

const ResourcesNotes = () => {

    const [isUpdateOrCreate,setIsUpdateOrCreate] = useState(false);
    const [notesResources,setNotesResources] = useState([]);
    const [notesDetails,setNotesDetails] = useState({});


    useEffect(()=> {
        getAllResources();
    },[]);

    const getAllResources = () => {
        firebase.firestore().collection("mains-yoga-question-bank").get().then((querySnapshot) => {
            let notes = [];
            querySnapshot.forEach((doc) => {
                console.log("dsd",doc.id);
                let note = doc.data();
                note.id = doc.id;
                notes.push(note);
            });
            console.log("getAllResources",notes)
            setNotesResources(notes)
        });
    };


    const onSave = () => {
        firebase.firestore().collection("mains-yoga-question-bank").add(notesDetails)
            .then((docRef) => {
                getAllResources();
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    };

    const handleChange = (e) => {
        let data = notesDetails;
        data[e.target.name] = [e.target.value];
        setNotesDetails(data);
    };


    return (
        <div>
            <button className='addBtn' onClick={()=> setIsUpdateOrCreate(!isUpdateOrCreate)}>Add New Mains YOGA question Bank(public)</button>
            {isUpdateOrCreate && <div>
                <div className='testSeriesInfo'>
                    <h5>Resource details</h5>
                    <div className="testSeriesLayout">
                        <label>Question Bank Title </label>
                        <input name='title' value={notesDetails.title} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Description </label>
                        <input name='description' value={notesDetails.description} onChange={handleChange} type="text" />
                    </div>
                    <div className="testSeriesLayout">
                        <label>Document URL</label>
                        <input name='url' value={notesDetails.url} onChange={handleChange} type="text" />
                    </div>
                    <button className='saveBtn' onClick={onSave}>save</button>
                </div>
            </div>}


            <div className='RWrapper'>
                {notesResources.map(data => {
                    return <div className='cardResWrapper'>
                        <div className='videoDesLayout'>
                            <p className='chapterName'>{data?.title}</p>
                            <p className='chapterDescription'>{data.description || 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book'}</p>
                            <p className='chapterReadTIme'>{data.readingTime} </p>

                        </div>
                    </div>})}
            </div>
        </div>
    );
};

export default ResourcesNotes;
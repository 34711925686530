import React from 'react';
import './NotesCard.css';

function NotesCardCMS(props) {
    return (
        <div>
            <div className="notesList" onClick={()=>props.onCourseClick(props.data)}>
                <p className='nName'>{props.data.name}</p>
                <p className='nDescription'>{props.data.description}</p>
                <p className='nPrice'>₹ {props.data.amount}</p>
                <p className='nTag'>{props.data.tags}</p>
                <button className='button' onClick={()=>props.onCourseClick(props.data)}>View Library</button>
                <button className="button" onClick={(e)=> {e.stopPropagation();props.onUpdateClick(props.data)}}>UPDATE</button>
                <button className="button" onClick={(e)=> {e.stopPropagation();props.onDelete(props.data)}}>DELETE</button>
            </div>
        </div>
    );
}

export default NotesCardCMS;

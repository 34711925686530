import React,{useState} from 'react';
import './ResourceCMS.css'
import ResourceYogaCMS from "./ResourceYoga/ResourceYogaCMS";
import ResourcesNotes from "./ResourceNotes/ResourcesNotes";
import ResourceYogaQuestionBank from "./ResourceYoga/ResourceYogaQuestionBank/ResourceYogaQuestionBank";

const ResourceCms = () => {

    const [activeTab,setActiveTab] = useState('notes');

    return (
        <div style={{width:'100%',height:'100%'}}>
            <div className='tabs'>
                <button className={`link ${activeTab === 'notes' ? 'active': '' }`}  onClick={()=> {
                    setActiveTab('notes')
                }}>Notes</button>
                <button className={`link ${activeTab === 'yoga_question_bank' ? 'active': '' }`}  onClick={()=> {
                    setActiveTab('yoga_question_bank')
                }}>Main Yoga Question Bank</button>
                <button className={`link ${activeTab === 'yoga_synopsis' ? 'active': '' }`}  onClick={()=> {
                    setActiveTab('yoga_synopsis')
                }}>Main Yoga Synopsis</button>
            </div>
            {activeTab === 'yoga_synopsis' ?  <ResourceYogaCMS/>  : activeTab === 'notes' ?  <ResourcesNotes/> : <ResourceYogaQuestionBank/>}
        </div>
    );
};

export default ResourceCms;